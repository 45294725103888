import avatarFitness from '../../assets/icons/services/Avatar-11.png';
import avatarGolf from '../../assets/icons/services/Avatar-10.png';
import avatarIndoor from '../../assets/icons/services/Avatar-9.png';
import avatarKids from '../../assets/icons/services/Avatar-8.png';
import avatarMeeting from '../../assets/icons/services/Avatar-7.png';
import avatarNight from '../../assets/icons/services/Avatar-6.png';
import avatarOutdoor from '../../assets/icons/services/Avatar-5.png';
import avatarParking from '../../assets/icons/services/Avatar-4.png';
import avatarBeach from '../../assets/icons/services/Avatar-3.png';
import avatarSpa from '../../assets/icons/services/Avatar-2.png';
import avatarTennis from '../../assets/icons/services/Avatar-1.png';
import avatarWine from '../../assets/icons/services/Avatar.png';

export const colors = ['#7856FF', '#FF7557', '#80E1D9', '#F8BC3B', '#3B87F8'];

export const hotel_profile_tags = {
    profiling_user: {
        favorite_food_type: [
            { icon: '🇫🇷', label: 'French' },
            { icon: '🇮🇹', label: 'Italian' },
            { icon: '🇪🇸', label: 'Spanish' },
            { icon: '🇲🇽', label: 'Mexican' },
            { icon: '🇵🇪', label: 'Peruvian' },
            { icon: '🇯🇵', label: 'Japanese' },
            { icon: '🇻🇳', label: 'Vietnamian' },
            { icon: '🇨🇳', label: 'Chinese' },
            { icon: '🇰🇷', label: 'Korean' },
            { icon: '🇲🇦', label: 'Morrocan' },
            { icon: '🌏', label: 'International' },
        ],
        communities: [
            { icon: '🪴', label: 'Ecologist' },
            { icon: '🐶', label: 'Pet lover' },
            { icon: '🔥', label: 'Cigar smokers' },
        ],
        allergies: [
            { icon: '🥜', label: 'Peanuts' },
            { icon: '🌿', label: 'Gluten' },
            { icon: '🥛', label: 'Milk' },
            { icon: '🥠', label: 'Nuts' },
            { icon: '🐟', label: 'Fish' },
            { icon: '🦪', label: 'Shellfish' },
            { icon: '🥚', label: 'Egg' },
            { icon: '🌳', label: 'Pollen' },
            { icon: '🌫️', label: 'Dust mite' },
        ],
        food_restrictions: [
            { icon: '🌿', label: 'Gluten free' },
            { icon: '🥛', label: 'Lactose free' },
            { icon: '🌱', label: 'Veggie' },
            { icon: '🌱', label: 'Vegan' },
            { icon: '✡', label: 'Kosher' },
            { icon: '🌙', label: 'Halal' },
            { icon: '🍫', label: 'Keto' },
            { icon: '🩸', label: 'Diabeties' },
            { icon: '🍶', label: 'Dairy-free' },
            { icon: '🍞', label: 'Low-Carb' },
            { icon: '🍸', label: 'Alcohol free' },
        ],
        preferences: [
            { icon: '🪴', label: 'Sustainability' },
            { icon: '🐶', label: 'Pet lover' },
            { icon: '🔥', label: 'Cigar advisor' },
            { icon: '🍷', label: 'Wine lover' },
            { icon: '🗺️', label: 'Local guide' },
            { icon: '📚', label: 'History master\'s' },
            { icon: '🍸', label: 'Cocktail expert' },
        ],
        property_type: [
            { icon: '🏨', label: 'Hotel' },
            { icon: '🛁', label: 'Apartment' },
            { icon: '🏡', label: 'Villa' },
            { icon: '🛳️', label: 'Boat' },
        ],
        stars: [
            { icon: '🌟', label: '5 stars' },
            { icon: '⭐️', label: '4 stars' },
            { icon: '🤩', label: 'Experience hotel' },
        ],
        environment: [
            { icon: '🌆', label: 'City' },
            { icon: '🏖️', label: 'Beach' },
            { icon: '🌳', label: 'Campaign' },
            { icon: '⛰️', label: 'Mountain' },
            { icon: '🌄', label: 'Unusual places' },
        ],
        design: [
            { icon: '🎭', label: 'Design' },
            { icon: '🛋️', label: 'Contemporary' },
            { icon: '🖼️', label: 'Traditional' },
            { icon: '🧧', label: 'Unusual' },
        ],
        music_brand_identity: [
            { icon: '🎻', label: 'Classical' },
            { icon: '🎷', label: 'Jazz' },
            { icon: '🎸', label: 'Rock' },
            { icon: '🥁', label: 'Pop' },
            { icon: '🎶', label: 'Blues' },
            { icon: '🎙️', label: 'Soul' },
            { icon: '🎤', label: 'Gospel' },
        ],
        facilities: [
            { icon: '🐬', label: 'Outdoor Pool' },
            { icon: '🛝', label: 'Indoor Pool' },
            { icon: '🏋️‍♀️', label: 'Fitness' },
            { icon: '💧', label: 'Spa' },
            { icon: '🏖️', label: 'Beach' },
            { icon: '👶', label: 'Kids club' },
            { icon: '🐶', label: 'Pet' },
            { icon: '🚗', label: 'Parking' },
            { icon: '🖥️', label: '24h front desk' },
            { icon: '🛎️', label: 'Room service' },
            { icon: '🌅', label: 'Rooftop' },
            { icon: '🛗', label: 'Acsensor' },
            { icon: '📆', label: 'Meeting room' },
            { icon: '🩰', label: 'Ball room' },
        ],
        foodie_choices: [
            { icon: '🥘', label: '1 restaurant' },
            { icon: '🍕', label: 'More than 1' },
            { icon: '🍸', label: 'Bar' },
            { icon: '☕️', label: 'Coffee' },
            { icon: '🫖', label: 'Tea lounge' },
            { icon: '🍹', label: 'Pool bar' },
            { icon: '⛱️', label: 'Beach club' },
        ],
        restaurant_food_type: [
            { icon: '🇫🇷', label: 'French' },
            { icon: '🇮🇹', label: 'Italian' },
            { icon: '🇪🇸', label: 'Spanish' },
            { icon: '🇲🇽', label: 'Mexican' },
            { icon: '🇵🇪', label: 'Peruvian' },
            { icon: '🇬🇷', label: 'Greek' },
            { icon: '🇯🇵', label: 'Japanese' },
            { icon: '🇨🇳', label: 'Chinese' },
            { icon: '🇰🇷', label: 'Korean' },
            { icon: '🇲🇦', label: 'Morrocan' },
        ],
        meal_plan: [
            { icon: '🥐', label: 'Breakfast included' },
            { icon: '🥗', label: 'Lunch included' },
            { icon: '🍛', label: 'Dinner included' },
            { icon: '🍱', label: 'All inclusive' }
        ],
        payment_type: [
            { icon: '🫴', label: 'Full refundable' },
            { icon: '💸', label: 'Book now, Pay later' }
        ],
        extra: [
            { icon: '🔥', label: 'Cigar Lounge' },
            { icon: '🕺', label: 'Nightclub' },
            { icon: '🏌️‍♀️', label: 'Private Coach' },
            { icon: '💇‍♀️', label: 'Hairstylist' },
            { icon: '💅', label: 'Mani/pedi' },
            { icon: '⌚️', label: 'Concierge service' },
            { icon: '✨', label: 'Waxing' },
            { icon: '🚖', label: 'Valet parking' },
            { icon: '🧺', label: 'Cleaning service' },
            { icon: '☀️', label: 'Solarium' },
            { icon: '🧒', label: 'Babysitting' },
            { icon: '💍', label: 'Shop' }
        ],
        spoken_languages: [
            { icon: '🇬🇧', label: 'English' },
            { icon: '🇺🇸', label: 'American' },
            { icon: '🇫🇷', label: 'French' },
            { icon: '🇮🇹', label: 'Italian' },
            { icon: '🇪🇸', label: 'Spanish' },
            { icon: '🇵🇹', label: 'Portuguese' },
            { icon: '🇩🇪', label: 'German' },
            { icon: '🇷🇺', label: 'Russian' },
            { icon: '🇮🇳', label: 'Hindi' },
            { icon: '🇸🇦', label: 'Arabic' },
            { icon: '🇯🇵', label: 'Japanese' },
            { icon: '🇻🇳', label: 'Vietnamian' },
            { icon: '🇨🇳', label: 'Chinese' },
            { icon: '🇰🇷', label: 'Korean' },
        ]
    },
    profiles_hotel: {
        mood: [
            { icon: '🥂', label: 'Leisure' },
            { icon: '💼', label: 'Business' },
            { icon: '👔', label: 'Bleisure' },
            { icon: '👫', label: 'Friends' },
            { icon: '👨‍👩‍👧', label: 'Family' },
            { icon: '🌹', label: 'Romantic' },
            { icon: '🎉', label: 'Party' },
            { icon: '🏀', label: 'Sport' }
        ],
        related_sport: [
            { icon: '🎾', label: 'Tennis' },
            { icon: '⛳️', label: 'Golf' },
            { icon: '🎾', label: 'Padel' },
            { icon: '🧘', label: 'Yoga' },
            { icon: '🌌', label: 'Wellness' },
            { icon: '🎣', label: 'Fishing' },
            { icon: '🐎', label: 'Horses' },
            { icon: '👟', label: 'Squash' },
            { icon: '⛷️', label: 'Ski' }
        ],
        travelers_travelling_with: [
            { icon: '👧', label: 'Kids' },
            { icon: '🧑‍💻', label: 'Colleagues' },
            { icon: '👫', label: 'Friends' },
            { icon: '💄', label: 'Secret' },
            { icon: '♥️', label: 'Partner' },
            { icon: '🐶', label: 'Pet' }
        ],
        nationalities: [
            { icon: '🇺🇸', label: 'American' },
            { icon: '🇫🇷', label: 'French' },
            { icon: '🇮🇹', label: 'Italian' },
            { icon: '🇪🇸', label: 'Spanish' },
            { icon: '🇩🇪', label: 'German' },
            { icon: '🇲🇽', label: 'Mexican' },
            { icon: '🇵🇪', label: 'Peruvian' },
            { icon: '🇬🇷', label: 'Greek' },
            { icon: '🇯🇵', label: 'Japanese' },
            { icon: '🇻🇳', label: 'Vietnamian' },
            { icon: '🇨🇳', label: 'Chinese' },
            { icon: '🇰🇷', label: 'Korean' },
            { icon: '🇲🇦', label: 'Moroccan' }
        ],
        vibe: [
            { icon: '👞', label: 'Trendy' },
            { icon: '👠', label: 'Charm' },
            { icon: '👜', label: 'Evasion' },
            { icon: '🏰', label: 'Castle life' },
            { icon: '❤️', label: 'Intimate' },
            { icon: '👟', label: 'Outgoing' }
        ],
        preferences: [
            { icon: '🪴', label: 'Sustainability' },
            { icon: '🐶', label: 'Pet lover' },
            { icon: '🔥', label: 'Cigar smokers' },
            { icon: '🍷', label: 'Wine lover' },
            { icon: '🌎', label: 'Globe trotter' },
            { icon: '🍸', label: 'Cocktail fan' }
        ]
    },
    room: {
        accessibility: [
            { icon: '🧑‍🦽', label: 'Accessible room' },
            { icon: '🛁', label: 'Adapted bath' },
            { icon: '🚿', label: 'Roll-In Shower' },
            { icon: '🧼', label: 'Walk-In Shower' },
            { icon: '🦽', label: 'Wheelchair' },
        ],
        room_location: [
            { icon: '⬆️', label: 'High floor' },
            { icon: '⬇️', label: 'Low floor' },
            { icon: '🌅', label: 'Sunrise exposure' },
            { icon: '🌄', label: 'Sunset exposure' },
            { icon: '🛗', label: 'Near elevator' },
        ],
        room_view: [
            { icon: '🏙️', label: 'City' },
            { icon: '💧', label: 'Pool' },
            { icon: '🏖️', label: 'Sea view' },
            { icon: '⛰️', label: 'Mountain' },
            { icon: '🏞️', label: 'Valley' },
            { icon: '🌄', label: 'Slope view' },
            { icon: '🍇', label: 'Vineyard' },
            { icon: '🛶', label: 'Lake' },
            { icon: '🐠', label: 'River view' },
        ],
        bed_type: [
            { icon: '👑', label: 'King bed' },
            { icon: '👸', label: 'Queen bed' },
            { icon: '🛏️', label: '2 double beds' }
        ],
        room_type: [
            { icon: '🔥', label: 'Smoking' },
            { icon: '🌬️', label: 'Non-Smoking' },
            { icon: '🌐', label: 'Connected room' },
            { icon: '💤', label: 'Silent room' }
        ],
        mattress_type: [
            { icon: '🪵', label: 'Firm' },
            { icon: '☁️', label: 'Soft' },
            { icon: '🛏️', label: 'Recent mattress' },
            { icon: '🛌', label: 'High-end mattress' }
        ],
        pillow_type: [
            { icon: '🆙', label: 'Extra foam pillow' },
            { icon: '🌫️', label: 'Foam pillows' },
            { icon: '🕊️', label: 'Extra feather pillows' },
            { icon: '🪶', label: 'Feather-free room' }
        ],
        bathroom: [
            { icon: '🚿', label: 'Shower' },
            { icon: '🛁', label: 'Bath' },
            { icon: '🚽', label: 'Restroom' },
        ],
        restroom: [
            { icon: '🚪', label: 'Separate' },
            { icon: '🇯🇵', label: 'Japanese' },
        ],
        facilities: [
            { icon: '💧', label: 'Pool' },
            { icon: '💦', label: 'Plunge pool' },
            { icon: '🫧', label: 'Jacuzzi' },
            { icon: '🧖', label: 'Sauna' },
            { icon: '🌫️', label: 'Hammam' },
            { icon: '🤸‍♀️', label: 'Gym' },
            { icon: '🧘', label: 'Yoga studio' },
        ],
        extra: [
            { icon: '🛏️', label: 'Rollaway bed' },
            { icon: '👧', label: 'Baby bed' },
            { icon: '♨️', label: 'Microwave' },
            { icon: '❄️', label: 'Fridge' },
            { icon: '💨', label: 'Air conditioning' },
            { icon: '📺', label: 'TV' },
            { icon: '🌅', label: 'Balcony' },
            { icon: '⬛️', label: 'Thick curtain' },
            { icon: '🪟', label: 'Shutter' },
        ],
        tv_preferences: [
            { icon: '📺', label: 'Smart TV' },
            { icon: '💻', label: 'Netflix' },
            { icon: '📺', label: 'Connected TV' }
        ]
    },
    pre_arrival: {
        time_arrive: [
            { icon: '⏱️', label: 'Time' },
            { icon: '⏳', label: 'Early Check-In if available' },
            { icon: '👍', label: 'No early Check-In needed' }
        ],
        special_occasion: [
            { icon: '🍰', label: 'Birthday' },
            { icon: '💍', label: 'Engagement party' },
            { icon: '🎬', label: 'Retirement' },
            { icon: '🎂', label: 'Anniversary' },
            { icon: '🎈', label: 'Bridesmaids party' },
            { icon: '🎉', label: 'Divorce party' }
        ],
        amenities: [
            { icon: '🥃', label: 'Alcohol' },
            { icon: '🧁', label: 'Sweet tooth' },
            { icon: '🥙', label: 'Salty tooth' }
        ],
        time_leave: [
            { icon: '⏰', label: 'Time' },
            { icon: '⌛', label: 'Late Check-Out if available' },
            { icon: '👍', label: 'No late Check-Out needed' }
        ],
        traveler_needs: [
            { icon: '🎁', label: 'Plan a special surprise' },
            { icon: '🚙', label: 'Transportation' }
        ]
    }
};

export const listServices = [
    { name: 'Fitness', icon: avatarFitness },
    { name: 'Golf', icon: avatarGolf },
    { name: 'Indoor Pool', icon: avatarIndoor },
    { name: 'Kids Club', icon: avatarKids },
    { name: 'Meeting Room', icon: avatarMeeting },
    { name: 'Night Club', icon: avatarNight },
    { name: 'Outdoor Pool', icon: avatarOutdoor },
    { name: 'Parking', icon: avatarParking },
    { name: 'Private Beach', icon: avatarBeach },
    { name: 'Spa', icon: avatarSpa },
    { name: 'Tennis', icon: avatarTennis },
    { name: 'Wine Cellar', icon: avatarWine },
];

export const listAdvantages = [
    { icon: '⏳', label: 'Early Check-In' },
    { icon: '⌛', label: 'Late Check-Out' },
    { icon: '🍞', label: 'Breakfast complementary' },
    { icon: '💳', label: '100$ Credit' },
    { icon: '💵', label: '80$ Credit' },
    { icon: '🌟', label: 'Upgrade' },
    { icon: '🎁', label: 'VIP Welcome gift' },
    { icon: '💆‍♀️', label: '30 min extra on treatments' },
    { icon: '🧖', label: 'Access to Spa' },
    { icon: '🌐', label: 'Free Wifi' }
];