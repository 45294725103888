import React, { useEffect, useRef, useState } from 'react';

import addImg from '../../../assets/icons/AddImgOrange.svg';
import { BiSolidChevronDown } from 'react-icons/bi';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { MdClose, MdCheck } from 'react-icons/md';
import { hotel_profile_tags } from '../../../utils/mock/HotelData';
import colors from '../../../colors/colors';
import { getUserListByRole } from '../../../api/user';

const labels = [
    { icon: '', label: 'Green Globe' },
    { icon: '', label: 'Green Tourism' },
    { icon: '', label: 'Earth Check' },
];

export const HotelProfileTab = ({ windowSize, managers, setManagers, lon, setLon, lat, setLat, hotel, setHotel, handleSubmit, navigate }) => {
    const [myRoles, setMyRoles] = useState(JSON.parse(localStorage.getItem('my_roles')));

    const [addPropertyTypeTag, setAddPropertyTypeTag] = useState('');
    const [addPropertyTypeTagFocused, setAddPropertyTypeTagFocused] = useState(false);
    const addPropertyTypeTagRef = useRef(null);

    const [addStarsTag, setAddStarsTag] = useState('');
    const [addStarsTagFocused, setAddStarsTagFocused] = useState(false);
    const addStarsTagRef = useRef(null);

    const [addEnvironmentTag, setAddEnvironmentTag] = useState('');
    const [addEnvironmentTagFocused, setAddEnvironmentTagFocused] = useState(false);
    const addEnvironmentTagRef = useRef(null);

    const [addDesignTag, setAddDesignTag] = useState('');
    const [addDesignTagFocused, setAddDesignTagFocused] = useState(false);
    const addDesignTagRef = useRef(null);

    const [addFacilitiesTag, setAddFacilitiesTag] = useState('');
    const [addFacilitiesTagFocused, setAddFacilitiesTagFocused] = useState(false);
    const addFacilitiesTagRef = useRef(null);

    const [addFoodieChoiceTag, setAddFoodieChoiceTag] = useState('');
    const [addFoodieChoiceTagFocused, setAddFoodieChoiceTagFocused] = useState(false);
    const addFoodieChoiceTagRef = useRef(null);

    const [addRestaurantFoodTypeTag, setAddRestaurantFoodTypeTag] = useState('');
    const [addRestaurantFoodTypeFocused, setAddRestaurantFoodTypeTagFocused] = useState(false);
    const addRestaurantFoodTypeRef = useRef(null);

    const [addMusicBrandIdentityTag, setAddMusicBrandIdentityTag] = useState('');
    const [addMusicBrandIdentityTagFocused, setAddMusicBrandIdentityTagFocused] = useState(false);
    const addMusicBrandIdentityTagRef = useRef(null);

    const [addPaymentTypeTag, setAddPaymentTypeTag] = useState('');
    const [addPaymentTypeTagFocused, setAddPaymentTypeTagFocused] = useState(false);
    const addPaymentTypeTagRef = useRef(null);

    const [addExtraTag, setAddExtraTag] = useState('');
    const [addExtraTagFocused, setAddExtraTagFocused] = useState(false);
    const addExtraTagRef = useRef(null);

    const [infoModal, setInfoModal] = useState(false);
    const [labelsOpen, setLabelsOpen] = useState(false);
    const [managersOpen, setManagersOpen] = useState(false);
    const managersRef = useRef(null);
    const [managersSearch, setManagersSearch] = useState('');
    const hiddenFileInput = React.useRef(null);
    const [photoInput, setPhotoInput] = useState(null);

    const handlePhoto = (key) => {
        hiddenFileInput.current.click();
        setPhotoInput(key);
    };

    const handleInputPhoto = async (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = async () => {
                const img = new Image();
                img.src = reader.result;

                img.onload = async () => {
                    const width = img.width;
                    const height = img.height;
                    const isPortrait = width < height;
                    const rotatedImageDataURL = isPortrait ? await rotateImage(reader.result) : reader.result;
                    const compressedImageDataURL = await compressImage(rotatedImageDataURL, 0.4);
                    let key = photoInput;
                    switch (key) {
                        case 'logo':
                        case 'banner':
                            setHotel({
                                ...hotel,
                                [key]: compressedImageDataURL,
                            });
                            break;
                        case 'images':
                            setHotel({
                                ...hotel,
                                [key]: [...hotel[key] ?? [], compressedImageDataURL]
                            });
                            break;
                        default:
                            break;
                    }
                    setPhotoInput(null);
                };
            };

            reader.readAsDataURL(file);
        }
    };

    const rotateImage = async (imageUrl) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = img.height;
                canvas.height = img.width;

                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate((90 * Math.PI) / 180);
                ctx.drawImage(img, -img.width / 2, -img.height / 2);

                resolve(canvas.toDataURL('image/jpeg', 0.8));
            };
        });
    };

    const compressImage = async (imageUrl, quality) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = img.width;
                canvas.height = img.height;

                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                canvas.toBlob((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(blob);
                }, 'image/jpeg', quality);
            };
        });
    };

    const handleInputText = (key, value) => {
        setHotel({ ...hotel, [key]: value });
    };

    const handleSelect = (key, value) => {
        if (hotel[key].includes(value)) {
            setHotel({ ...hotel, [key]: hotel[key].filter(el => el !== value) });
        } else {
            setHotel({ ...hotel, [key]: [...hotel[key], value] });
        }
    };

    const handleRemoveSelect = (key, value) => {
        if (hotel[key].includes(value)) {
            setHotel({ ...hotel, [key]: hotel[key].filter(el => el !== value) });
        }
    };

    const handleTag = (key, value) => {
        if (hotel[key] === undefined) {
            setHotel({ ...hotel, [key]: [value] });
            return;
        }
        if (containsObject(value, hotel[key])) {
            setHotel({ ...hotel, [key]: hotel[key].filter(el => el.icon !== value.icon && el.label !== value.label) });
        } else {
            setHotel({ ...hotel, [key]: [...hotel[key], value] });
        }
    };

    const containsObject = (obj, list) => {
        if (list) {
            return list.some(item => item.icon === obj.icon && item.label === obj.label);
        } else {
            return false;
        }
    };

    const retrieveManagers = (search) => {
        getUserListByRole(0, 10, search, 'hotelManager', response => {
            if (response?.status === 'success') {
                setManagers(response?.content?.users);
            }
        });
    };

    useEffect(() => {
        retrieveManagers(managersSearch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (managersOpen && managersRef.current && !managersRef.current.contains(event.target)) {
                setManagersOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [
        managersOpen,
        managersRef
    ]);

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
        >
            {infoModal && (
                <div
                    className="filter-darker"
                    onClick={() => {
                        if (infoModal) {
                            setInfoModal(false);
                        }
                    }}
                />
            )}
            <div className="subtitle mt50 ml50">General information</div>
            <div className="border-container r8 mt20">

                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Hotel<span className='primary-text-color'>*</span></div>
                    <div className='row-view items-center mr20 flex1'>
                        <input
                            required
                            className="input-text mr20"
                            name="name"
                            placeholder="Hotel name..."
                            value={hotel?.name}
                            onChange={(e) => {
                                handleInputText(e.target.name, e.target.value.toUpperCase());
                            }}
                        />
                        <div
                            className={`checkbox cursor-pointer r4 mr10 ${hotel?.active && 'selected'} ${!myRoles?.includes('admin') && 'opacity60'}`}
                            onClick={() => {
                                if (myRoles?.includes('admin'))
                                    setHotel({ ...hotel, active: !hotel.active });
                            }}
                        >
                            {hotel?.active && <MdCheck color='white' />}
                        </div>
                        <div className={`bold mr10 ${!myRoles?.includes('admin') && 'opacity60'}`}>Set active</div>
                        <div className='relative row-view items-center'>
                            <BsFillQuestionCircleFill color={colors.primary} size={20} onClick={() => setInfoModal(!infoModal)} />
                            <div className={`info-modal ${infoModal && 'active'}`}>
                                Set <span className='primary-text-color bold'>"The hotel"</span> active.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Showcase</div>
                    <div className={`row-view flex1 justify-between wrap gap100`}>
                        <div className='row-view items-center flex04 gap100'>
                            <div className="subtitle">Logo</div>
                            <div>
                                {!hotel?.logo ? (
                                    <div className='row-view items-center gap20'>
                                        <div className="section-subtitle">
                                            (JPGs or PNGs of at least 150x150px)
                                        </div>
                                        <div
                                            className="row-view border-container b-dashed b2 r8 p20 cursor-pointer items-center justify-center"
                                            onClick={() => handlePhoto('logo')}
                                        >
                                            <img src={addImg} alt="addImg" />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="p10 relative">
                                        <img
                                            className="r8 b"
                                            src={hotel?.logo}
                                            alt="hotelPhoto"
                                            style={{
                                                width: '80px',
                                                height: '80px',
                                                objectFit: 'contain',
                                            }}
                                        />
                                        <div
                                            className="close-icon"
                                            onClick={() =>
                                                setHotel({
                                                    ...hotel,
                                                    logo: null
                                                })
                                            }
                                        >
                                            <MdClose size={20} color="white" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row-view items-center flex1 gap100'>
                            <div className="subtitle mr20">Banner</div>
                            <div>
                                {!hotel?.banner ? (
                                    <div className='row-view items-center gap20'>
                                        <div className="section-subtitle">
                                            (JPGs or PNGs of at least 1080x566 px)
                                        </div>
                                        <div
                                            className="row-view border-container b-dashed b2 r8 p20 cursor-pointer items-center justify-center"
                                            onClick={() => handlePhoto('banner')}
                                        >
                                            <img src={addImg} alt="addImg" />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="p10 relative w300">
                                        <img
                                            className="r8 b w100"
                                            src={hotel?.banner}
                                            alt="hotelPhoto"
                                        />
                                        <div
                                            className="close-icon"
                                            onClick={() =>
                                                setHotel({
                                                    ...hotel,
                                                    banner: null
                                                })
                                            }
                                        >
                                            <MdClose size={20} color="white" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Address<span className='primary-text-color'>*</span></div>
                    <div className='row-view items-center flex1'>
                        <div className='row-view flex07 wrap gap20'>
                            <input
                                required
                                className="input-text"
                                name="street"
                                placeholder="Address..."
                                value={hotel?.address?.street}
                                onChange={(e) => setHotel({ ...hotel, address: { ...hotel?.address, street: e.target.value } })}
                            />
                            <input
                                required
                                className="input-text"
                                name="city"
                                placeholder="City..."
                                value={hotel?.address?.city}
                                onChange={(e) => setHotel({ ...hotel, address: { ...hotel?.address, city: e.target.value } })}
                            />
                            <input
                                required
                                className="input-text"
                                name="zipcode"
                                placeholder="Zip code..."
                                value={hotel?.address?.zipCode}
                                onChange={(e) => setHotel({ ...hotel, address: { ...hotel?.address, zipCode: e.target.value } })}
                            />
                            <input
                                required
                                className="input-text"
                                name="country"
                                placeholder="Country..."
                                value={hotel?.address?.country}
                                onChange={(e) => setHotel({ ...hotel, address: { ...hotel?.address, country: e.target.value } })}
                            />
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Phone number</div>
                    <div className='column-view flex1 justify-between'>
                        {hotel?.phone?.map((item, index) => {
                            return (
                                <div key={index} className='row-view items-center gap20 wrap mb10'>
                                    <input
                                        className="input-text"
                                        name="phone"
                                        placeholder="Phone number..."
                                        value={item?.phone}
                                        onChange={(e) => {
                                            let phones = hotel?.phone;
                                            phones[index].phone = e.target.value;
                                            setHotel({ ...hotel, phone: phones });
                                        }}
                                    />
                                    <input
                                        className="input-text"
                                        name="phone"
                                        placeholder="Description..."
                                        value={item?.description}
                                        onChange={(e) => {
                                            let phones = hotel?.phone;
                                            phones[index].description = e.target.value;
                                            setHotel({ ...hotel, phone: phones });
                                        }}
                                    />
                                    {hotel?.phone?.length > 1 && <MdClose className='cursor-pointer' color={colors.primary} size={25} onClick={() => setHotel({ ...hotel, phone: hotel.phone.filter(el => el !== item) })} />}
                                </div>
                            );
                        })}
                        <div className='row-view'>
                            <div className='secondary-button borderless p12 grey-text' onClick={() => setHotel({ ...hotel, phone: [...hotel.phone, { phone: '', description: '' }] })}>+Add phone</div>
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Website</div>
                    <div className='row-view flex1 items-center'><input
                        className="input-text"
                        name="website"
                        placeholder="Website..."
                        value={hotel?.website}
                        onChange={(e) => handleInputText(e.target.name, e.target.value)}
                    />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Overview</div>
                    <div className="row-view flex1">
                        <textarea
                            className='input-text flex1'
                            rows={10}
                            value={hotel?.description}
                            name="description"
                            onChange={(e) => handleInputText(e.target.name, e.target.value)}
                        />
                    </div>
                </div>

                <div className="row-view items-center p20">
                    <div className="section-title flex04">Hotel Display</div>
                    <div className='flex1 ofx-hidden'>
                        <div className='row-view flex1 items-center ofx-scroll'>
                            {hotel?.images?.map((image, index) => (
                                <div className='relative p10' key={index}>
                                    <img
                                        width={250}
                                        height={150}
                                        style={{ objectFit: 'cover' }}
                                        alt={index}
                                        key={index}
                                        src={image}
                                        className='r8'
                                    />
                                    <div
                                        className="close-icon"
                                        onClick={() => {
                                            let imgs = hotel?.images;
                                            imgs.splice(index, 1);
                                            setHotel({
                                                ...hotel,
                                                imgaes: imgs,
                                            });
                                        }}
                                    >
                                        <MdClose size={20} color="white" />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={`row-view items-center gap20 ${hotel?.images?.length > 0 && 'mt20'}`}>
                            <div
                                className="row-view border-container b-dashed b2 r8 p20 cursor-pointer items-center justify-center"
                                onClick={() => handlePhoto('images')}
                            >
                                <img src={addImg} alt="addImg" />
                            </div>
                            <div className="section-subtitle">
                                (JPGs or PNGs of at least 1080x566 px)
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="subtitle mt50 ml50">Hotel Specifications</div>

            <div className="border-container r8 mt20">
                <div className="row-view items-center p20 bb">
                    <div className='section-title flex04'>Manager</div>
                    <div className='row-view flex1 items-center'>
                        <div className='relative' style={{ paddingTop: "2%", paddingBottom: "2%", display: "inline-block" }}>
                            {managersOpen ?
                                <input
                                    type='text'
                                    value={managersSearch}
                                    className='input-text w300'
                                    autoFocus
                                    onChange={(e) => {
                                        setManagersSearch(e.target.value);
                                        retrieveManagers(e.target.value);
                                    }}
                                />
                                :
                                <div
                                    className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer'
                                    onClick={() => setManagersOpen(!managersOpen)}
                                >
                                    <div>
                                        Select manager
                                    </div>
                                    <BiSolidChevronDown />
                                </div>
                            }
                            <div className={`select-menu ${managersOpen && 'open'}`} style={{ maxHeight: 300, overflow: "scroll" }} ref={managersRef}>
                                {managers?.length !== 0 ? (
                                    <>
                                        {managers?.map((m, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className='select-item'
                                                    onClick={() => {
                                                        handleSelect('manager', m);
                                                    }}
                                                >
                                                    <span className='section-title'>
                                                        {m?.firstname} {m?.lastname}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <div className='select-item'>
                                            No managers found
                                        </div>
                                    </>
                                )}
                            </div>
                            {hotel?.manager?.length !== 0 && hotel?.manager?.map((m, i) => {
                                return (
                                    <div key={i} className='border-container r8 row-view items-center justify-between w300 ph20 pv12 mt10'>
                                        <div>{m?.firstname} {m?.lastname}</div>
                                        <MdClose onClick={() => handleRemoveSelect('manager', m)} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Opening year</div>
                    <div className='row-view flex1 items-center'><input
                        className="input-text"
                        name="openingYear"
                        placeholder="Opening year..."
                        value={hotel?.openingYear}
                        onChange={(e) => handleInputText(e.target.name, e.target.value)}
                    />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Refit year</div>
                    <div className='row-view flex1 items-center'><input
                        className="input-text"
                        name="refitYear"
                        placeholder="Refit year..."
                        value={hotel?.refitYear}
                        onChange={(e) => handleInputText(e.target.name, e.target.value)}
                    />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Number of rooms</div>
                    <div className='row-view flex1 items-center'>
                        <h1 className='mr10'>{hotel?.numberOfRooms ?? 0}</h1><h3>Rooms</h3>
                    </div>
                </div>

                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Our labels</div>
                    <div className='row-view flex1 items-center'>
                        <div className="relative">
                            <div
                                className="border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer"
                                onClick={() => setLabelsOpen(!labelsOpen)}
                            >
                                <div>Select labels</div>
                                <BiSolidChevronDown />
                            </div>
                            <div className={`select-menu ${labelsOpen && 'open'}`}>
                                {labels?.length !== 0 ? (
                                    <>
                                        {labels.map((label, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="select-item"
                                                    onClick={() => {
                                                        handleSelect(
                                                            'labels',
                                                            label
                                                        );
                                                        setLabelsOpen(false);
                                                    }}
                                                >
                                                    <span className="section-title">
                                                        {label.label}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <div className="select-item">
                                            No labels found
                                        </div>
                                    </>
                                )}
                            </div>
                            {hotel?.labels?.length !== 0 && hotel?.labels?.map((l, i) => {
                                return (
                                    <div key={i} className='border-container r8 row-view items-center justify-between w300 ph20 pv12 mt10'>
                                        <div>{l.label}</div>
                                        <MdClose onClick={() => handleRemoveSelect('labels', l)} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Rate access code (RAC)</div>
                    <div className='row-view flex1 items-center'><input
                        className="input-text"
                        name="rac"
                        placeholder="RAC..."
                        value={hotel?.rac}
                        onChange={(e) => handleInputText(e.target.name, e.target.value)}
                    />
                    </div>
                </div>
                <div className="row-view items-center p20">
                    <div className="section-title flex04">Amadeus code<span className='primary-text-color'>*</span></div>
                    <div className='row-view flex1 items-center'>
                        <input
                            required
                            className="input-text"
                            name="amadeus"
                            placeholder="Amadeus code..."
                            value={hotel?.amadeus}
                            onChange={(e) => handleInputText(e.target.name, e.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className="subtitle mt50 ml50">Hotel profiling</div>
            <div className="border-container r8 mt20">
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">
                        Property type
                    </div>
                    <div className="flex1">
                        <div>
                            <div className="row-view items-center wrap flex1">
                                {hotel_profile_tags.profiling_user.property_type.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view tag mr20 mt10 mb10 ${hotel?.property_type && hotel?.property_type?.includes(item) &&
                                                'selected'
                                                }`}
                                            onClick={() =>
                                                handleTag('property_type', item)
                                            }
                                        >
                                            <div>{item.icon} {item.label}</div>
                                        </div>
                                    );
                                })}
                                {hotel?.property_type?.filter(el => !hotel_profile_tags.profiling_user.property_type.includes(el))?.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view tag mr20 mt10 mb10 ${hotel?.property_type && hotel?.property_type?.includes(item) &&
                                                'selected'
                                                }`}
                                            onClick={() =>
                                                handleTag('property_type', item)
                                            }
                                        >
                                            <div>{item.icon} {item.label}</div>
                                        </div>
                                    );
                                })}
                                <input
                                    type='text'
                                    ref={addPropertyTypeTagRef}
                                    placeholder={!addPropertyTypeTagFocused && '+Add tag'}
                                    className={`borderless none-outline mr20 mt10 mb10 ${addPropertyTypeTagFocused && 'tag'}`}
                                    size={addPropertyTypeTag.length || 4}
                                    value={addPropertyTypeTag}
                                    onChange={(e) => setAddPropertyTypeTag(e.target.value)}
                                    onFocus={() => setAddPropertyTypeTagFocused(true)}
                                    onBlur={() => {
                                        let tag = { icon: '🏷️', label: addPropertyTypeTag };
                                        if (addPropertyTypeTag.length < 1 || containsObject(tag, hotel?.property_type)) {
                                            setAddPropertyTypeTag('');
                                            setAddPropertyTypeTagFocused(false);
                                            addPropertyTypeTagRef.current.blur();
                                            return;
                                        }
                                        let tags = hotel?.property_type ?? [];
                                        tags.push(tag);
                                        setHotel({ ...hotel, property_type: tags });
                                        setAddPropertyTypeTag('');
                                        setAddPropertyTypeTagFocused(false);
                                        addPropertyTypeTagRef.current.blur();
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            addPropertyTypeTagRef.current.blur();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">

                    <div className="section-title flex04">
                        Stars
                    </div>
                    <div className="flex1">
                        <div>

                            <div className="row-view wrap flex1">
                                {hotel_profile_tags.profiling_user.stars.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view tag mr20 mt10 mb10 ${hotel?.stars && hotel?.stars?.includes(item) &&
                                                'selected'
                                                }`}
                                            onClick={() =>
                                                handleTag('stars', item)
                                            }
                                        >
                                            <div>{item.icon} {item.label}</div>
                                        </div>
                                    );
                                })}
                                {Array.isArray(hotel?.stars) &&
                                    hotel.stars.filter(el => !hotel_profile_tags.profiling_user.stars.includes(el)).map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`row-view tag mr20 mt10 mb10 ${hotel?.stars && hotel?.stars.includes(item) && 'selected'}`}
                                                onClick={() => handleTag('stars', item)}
                                            >
                                                <div>{item.icon} {item.label}</div>
                                            </div>
                                        );
                                    })
                                }
                                <input
                                    type='text'
                                    ref={addStarsTagRef}
                                    placeholder={!addStarsTagFocused && '+Add tag'}
                                    className={`borderless none-outline mr20 mt10 mb10 ${addStarsTagFocused && 'tag'}`}
                                    size={addStarsTag.length || 4}
                                    value={addStarsTag}
                                    onChange={(e) => setAddStarsTag(e.target.value)}
                                    onFocus={() => setAddStarsTagFocused(true)}
                                    onBlur={() => {
                                        let tag = { icon: '🏷️', label: addStarsTag };
                                        if (addStarsTag.length < 1 || containsObject(tag, hotel?.communities)) {
                                            setAddStarsTag('');
                                            setAddStarsTagFocused(false);
                                            addStarsTagRef.current.blur();
                                            return;
                                        }
                                        let tags = hotel?.stars ?? [];
                                        tags.push(tag);
                                        setHotel({ ...hotel, stars: tags });
                                        setAddStarsTag('');
                                        setAddStarsTagFocused(false);
                                        addStarsTagRef.current.blur();
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            addStarsTagRef.current.blur();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">

                    <div className="section-title flex04">
                        Environment
                    </div>
                    <div className="flex1">
                        <div>
                            <div className="row-view wrap flex1">
                                {hotel_profile_tags.profiling_user.environment.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view tag mr20 mt10 mb10 ${hotel?.environment && hotel?.environment?.includes(item) &&
                                                'selected'
                                                }`}
                                            onClick={() =>
                                                handleTag('environment', item)
                                            }
                                        >
                                            <div>{item.icon} {item.label}</div>
                                        </div>
                                    );
                                })}
                                {hotel?.environment?.filter(el => !hotel_profile_tags.profiling_user.environment.includes(el))?.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view tag mr20 mt10 mb10 ${hotel?.environment && hotel?.environment?.includes(item) &&
                                                'selected'
                                                }`}
                                            onClick={() =>
                                                handleTag('environment', item)
                                            }
                                        >
                                            <div>{item.icon} {item.label}</div>
                                        </div>
                                    );
                                })}
                                <input
                                    type='text'
                                    ref={addEnvironmentTagRef}
                                    placeholder={!addEnvironmentTagFocused && '+Add tag'}
                                    className={`borderless none-outline mr20 mt10 mb10 ${addEnvironmentTagFocused && 'tag'}`}
                                    size={addEnvironmentTag.length || 4}
                                    value={addEnvironmentTag}
                                    onChange={(e) => setAddEnvironmentTag(e.target.value)}
                                    onFocus={() => setAddEnvironmentTagFocused(true)}
                                    onBlur={() => {
                                        let tag = { icon: '🏷️', label: addEnvironmentTag };
                                        if (addEnvironmentTag.length < 1 || containsObject(tag, hotel?.environment)) {
                                            setAddEnvironmentTag('');
                                            setAddEnvironmentTagFocused(false);
                                            addEnvironmentTagRef.current.blur();
                                            return;
                                        }
                                        let tags = hotel?.environment ?? [];
                                        tags.push(tag);
                                        setHotel({ ...hotel, environment: tags });
                                        setAddEnvironmentTag('');
                                        setAddEnvironmentTagFocused(false);
                                        addEnvironmentTagRef.current.blur();
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            addEnvironmentTagRef.current.blur();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">

                    <div className="section-title flex04">
                        Design
                    </div>
                    <div className="flex1">
                        <div>

                            <div className="row-view wrap flex1">
                                {hotel_profile_tags.profiling_user.design.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view tag mr20 mt10 mb10 ${hotel?.design && hotel?.design?.includes(item) &&
                                                'selected'
                                                }`}
                                            onClick={() =>
                                                handleTag('design', item)
                                            }
                                        >
                                            <div>{item.icon} {item.label}</div>
                                        </div>
                                    );
                                })}
                                {hotel?.design?.filter(el => !hotel_profile_tags.profiling_user.design.includes(el))?.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view tag mr20 mt10 mb10 ${hotel?.design && hotel?.design?.includes(item) &&
                                                'selected'
                                                }`}
                                            onClick={() =>
                                                handleTag('design', item)
                                            }
                                        >
                                            <div>{item.icon} {item.label}</div>
                                        </div>
                                    );
                                })}
                                <input
                                    type='text'
                                    ref={addDesignTagRef}
                                    placeholder={!addDesignTagFocused && '+Add tag'}
                                    className={`borderless none-outline mr20 mt10 mb10 ${addDesignTagFocused && 'tag'}`}
                                    size={addDesignTag.length || 4}
                                    value={addDesignTag}
                                    onChange={(e) => setAddDesignTag(e.target.value)}
                                    onFocus={() => setAddDesignTagFocused(true)}
                                    onBlur={() => {
                                        let tag = { icon: '🏷️', label: addDesignTag };
                                        if (addDesignTag?.length < 1 || containsObject(tag, hotel?.design)) {
                                            setAddDesignTag('');
                                            setAddDesignTagFocused(false);
                                            addDesignTagRef.current.blur();
                                            return;
                                        }
                                        let tags = hotel?.design ?? [];
                                        tags.push(tag);
                                        setHotel({ ...hotel, design: tags });
                                        setAddDesignTag('');
                                        setAddDesignTagFocused(false);
                                        addDesignTagRef.current.blur();
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            addDesignTagRef.current.blur();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">

                    <div className="section-title flex04">
                        Facilities
                    </div>
                    <div className="flex1">
                        <div>

                            <div className="row-view wrap flex1">
                                {hotel_profile_tags.profiling_user.facilities.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view tag mr20 mt10 mb10 ${hotel?.facilities && hotel?.facilities?.includes(item) &&
                                                'selected'
                                                }`}
                                            onClick={() =>
                                                handleTag('facilities', item)
                                            }
                                        >
                                            <div>{item.icon} {item.label}</div>
                                        </div>
                                    );
                                })}
                                {hotel?.facilities?.filter(el => !hotel_profile_tags.profiling_user.facilities.includes(el))?.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view tag mr20 mt10 mb10 ${hotel?.facilities && hotel?.facilities?.includes(item) &&
                                                'selected'
                                                }`}
                                            onClick={() =>
                                                handleTag('facilities', item)
                                            }
                                        >
                                            <div>{item.icon} {item.label}</div>
                                        </div>
                                    );
                                })}
                                <input
                                    type='text'
                                    ref={addFacilitiesTagRef}
                                    placeholder={!addFacilitiesTagFocused && '+Add tag'}
                                    className={`borderless none-outline mr20 mt10 mb10 ${addFacilitiesTagFocused && 'tag'}`}
                                    size={addFacilitiesTag.length || 4}
                                    value={addFacilitiesTag}
                                    onChange={(e) => setAddFacilitiesTag(e.target.value)}
                                    onFocus={() => setAddFacilitiesTagFocused(true)}
                                    onBlur={() => {
                                        let tag = { icon: '🏷️', label: addFacilitiesTag };
                                        if (addFacilitiesTag.length < 1 || containsObject(tag, hotel?.facilities)) {
                                            setAddFacilitiesTag('');
                                            setAddFacilitiesTagFocused(false);
                                            addFacilitiesTagRef.current.blur();
                                            return;
                                        }
                                        let tags = hotel?.facilities ?? [];
                                        tags.push(tag);
                                        setHotel({ ...hotel, facilities: tags });
                                        setAddFacilitiesTag('');
                                        setAddFacilitiesTagFocused(false);
                                        addFacilitiesTagRef.current.blur();
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            addFacilitiesTagRef.current.blur();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">

                    <div className="section-title flex04">
                        Foodie Choices
                    </div>
                    <div className="flex1">
                        <div>

                            <div className="row-view wrap flex1">
                                {hotel_profile_tags.profiling_user.foodie_choices.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view tag mr20 mt10 mb10 ${hotel?.foodie_choices && hotel?.foodie_choices?.includes(item) &&
                                                'selected'
                                                }`}
                                            onClick={() =>
                                                handleTag('foodie_choices', item)
                                            }
                                        >
                                            <div>{item.icon} {item.label}</div>
                                        </div>
                                    );
                                })}
                                {hotel?.foodie_choices?.filter(el => !hotel_profile_tags.profiling_user.foodie_choices.includes(el))?.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view tag mr20 mt10 mb10 ${hotel?.foodie_choices && hotel?.foodie_choices?.includes(item) &&
                                                'selected'
                                                }`}
                                            onClick={() =>
                                                handleTag('foodie_choices', item)
                                            }
                                        >
                                            <div>{item.icon} {item.label}</div>
                                        </div>
                                    );
                                })}
                                <input
                                    type='text'
                                    ref={addFoodieChoiceTagRef}
                                    placeholder={!addFoodieChoiceTagFocused && '+Add tag'}
                                    className={`borderless none-outline mr20 mt10 mb10 ${addFoodieChoiceTagFocused && 'tag'}`}
                                    size={addFoodieChoiceTag.length || 4}
                                    value={addFoodieChoiceTag}
                                    onChange={(e) => setAddFoodieChoiceTag(e.target.value)}
                                    onFocus={() => setAddFoodieChoiceTagFocused(true)}
                                    onBlur={() => {
                                        let tag = { icon: '🏷️', label: addFoodieChoiceTag };
                                        if (addFoodieChoiceTag?.length < 1 || containsObject(tag, hotel?.foodie_choices)) {
                                            setAddFoodieChoiceTag('');
                                            setAddFoodieChoiceTagFocused(false);
                                            addFoodieChoiceTagRef.current.blur();
                                            return;
                                        }
                                        let tags = hotel?.foodie_choices ?? [];
                                        tags.push(tag);
                                        setHotel({ ...hotel, foodie_choices: tags });
                                        setAddFoodieChoiceTag('');
                                        setAddFoodieChoiceTagFocused(false);
                                        addFoodieChoiceTagRef.current.blur();
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            addFoodieChoiceTagRef.current.blur();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">

                    <div className="section-title flex04">
                        Restaurant food type
                    </div>
                    <div className="flex1">
                        <div>

                            <div className="row-view wrap flex1">
                                {hotel_profile_tags.profiling_user.restaurant_food_type.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view tag mr20 mt10 mb10 ${hotel?.restaurant_food_type && hotel?.restaurant_food_type?.includes(item) &&
                                                'selected'
                                                }`}
                                            onClick={() =>
                                                handleTag('restaurant_food_type', item)
                                            }
                                        >
                                            <div>{item.icon} {item.label}</div>
                                        </div>
                                    );
                                })}
                                {hotel?.restaurant_food_type?.filter(el => !hotel_profile_tags.profiling_user.restaurant_food_type.includes(el))?.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view tag mr20 mt10 mb10 ${hotel?.restaurant_food_type && hotel?.restaurant_food_type?.includes(item) &&
                                                'selected'
                                                }`}
                                            onClick={() =>
                                                handleTag('restaurant_food_type', item)
                                            }
                                        >
                                            <div>{item.icon} {item.label}</div>
                                        </div>
                                    );
                                })}
                                <input
                                    type='text'
                                    ref={addRestaurantFoodTypeRef}
                                    placeholder={!addRestaurantFoodTypeFocused && '+Add tag'}
                                    className={`borderless none-outline mr20 mt10 mb10 ${addRestaurantFoodTypeFocused && 'tag'}`}
                                    size={addRestaurantFoodTypeTag.length || 4}
                                    value={addRestaurantFoodTypeTag}
                                    onChange={(e) => setAddRestaurantFoodTypeTag(e.target.value)}
                                    onFocus={() => setAddRestaurantFoodTypeTagFocused(true)}
                                    onBlur={() => {
                                        let tag = { icon: '🏷️', label: addRestaurantFoodTypeTag };
                                        if (addRestaurantFoodTypeTag?.length < 1 || containsObject(tag, hotel?.restaurant_food_type)) {
                                            setAddRestaurantFoodTypeTag('');
                                            setAddRestaurantFoodTypeTagFocused(false);
                                            addRestaurantFoodTypeRef.current.blur();
                                            return;
                                        }
                                        let tags = hotel?.restaurant_food_type ?? [];
                                        tags.push(tag);
                                        setHotel({ ...hotel, restaurant_food_type: tags });
                                        setAddRestaurantFoodTypeTag('');
                                        setAddRestaurantFoodTypeTagFocused(false);
                                        addRestaurantFoodTypeRef.current.blur();
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            addRestaurantFoodTypeRef.current.blur();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-view items-center p20 bb">

                    <div className="section-title flex04">
                        Music brand identity
                    </div>
                    <div className="flex1">
                        <div>

                            <div className="row-view wrap flex1">
                                {hotel_profile_tags.profiling_user.music_brand_identity.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view tag mr20 mt10 mb10 ${hotel?.music_brand_identity && hotel?.music_brand_identity?.includes(item) &&
                                                'selected'
                                                }`}
                                            onClick={() =>
                                                handleTag('music_brand_identity', item)
                                            }
                                        >
                                            <div>{item.icon} {item.label}</div>
                                        </div>
                                    );
                                })}
                                {hotel?.music_brand_identity?.filter(el => !hotel_profile_tags.profiling_user.music_brand_identity.includes(el))?.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view tag mr20 mt10 mb10 ${hotel?.music_brand_identity && hotel?.music_brand_identity?.includes(item) &&
                                                'selected'
                                                }`}
                                            onClick={() =>
                                                handleTag('music_brand_identity', item)
                                            }
                                        >
                                            <div>{item.icon} {item.label}</div>
                                        </div>
                                    );
                                })}
                                <input
                                    type='text'
                                    ref={addMusicBrandIdentityTagRef}
                                    placeholder={!addMusicBrandIdentityTagFocused && '+Add tag'}
                                    className={`borderless none-outline mr20 mt10 mb10 ${addMusicBrandIdentityTagFocused && 'tag'}`}
                                    size={addMusicBrandIdentityTag.length || 4}
                                    value={addMusicBrandIdentityTag}
                                    onChange={(e) => setAddMusicBrandIdentityTag(e.target.value)}
                                    onFocus={() => setAddMusicBrandIdentityTagFocused(true)}
                                    onBlur={() => {
                                        let tag = { icon: '🏷️', label: addMusicBrandIdentityTag };
                                        if (addMusicBrandIdentityTag?.length < 1 || containsObject(tag, hotel?.music_brand_identity)) {
                                            setAddMusicBrandIdentityTag('');
                                            setAddMusicBrandIdentityTagFocused(false);
                                            addMusicBrandIdentityTagRef.current.blur();
                                            return;
                                        }
                                        let tags = hotel?.music_brand_identity ?? [];
                                        tags.push(tag);
                                        setHotel({ ...hotel, music_brand_identity: tags });
                                        setAddMusicBrandIdentityTag('');
                                        setAddMusicBrandIdentityTagFocused(false);
                                        addMusicBrandIdentityTagRef.current.blur();
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            addMusicBrandIdentityTagRef.current.blur();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row-view items-center p20">

                    <div className="section-title flex04">
                        Extra
                    </div>
                    <div className="flex1">
                        <div>

                            <div className="row-view wrap flex1">
                                {hotel_profile_tags.profiling_user.extra.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view tag mr20 mt10 mb10 ${hotel?.extra && hotel?.extra?.includes(item) &&
                                                'selected'
                                                }`}
                                            onClick={() =>
                                                handleTag('extra', item)
                                            }
                                        >
                                            <div>{item.icon} {item.label}</div>
                                        </div>
                                    );
                                })}
                                {hotel?.extra?.filter(el => !hotel_profile_tags.profiling_user.extra.includes(el))?.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view tag mr20 mt10 mb10 ${hotel?.extra && hotel?.extra?.includes(item) &&
                                                'selected'
                                                }`}
                                            onClick={() =>
                                                handleTag('extra', item)
                                            }
                                        >
                                            <div>{item.icon} {item.label}</div>
                                        </div>
                                    );
                                })}
                                <input
                                    type='text'
                                    ref={addExtraTagRef}
                                    placeholder={!addExtraTagFocused && '+Add tag'}
                                    className={`borderless none-outline mr20 mt10 mb10 ${addExtraTagFocused && 'tag'}`}
                                    size={addExtraTag.length || 4}
                                    value={addExtraTag}
                                    onChange={(e) => setAddExtraTag(e.target.value)}
                                    onFocus={() => setAddExtraTagFocused(true)}
                                    onBlur={() => {
                                        let tag = { icon: '🏷️', label: addExtraTag };
                                        if (addExtraTag?.length < 1 || containsObject(tag, hotel?.extra)) {
                                            setAddExtraTag('');
                                            setAddExtraTagFocused(false);
                                            addExtraTagRef.current.blur();
                                            return;
                                        }
                                        let tags = hotel?.extra ?? [];
                                        tags.push(tag);
                                        setHotel({ ...hotel, extra: tags });
                                        setAddExtraTag('');
                                        setAddExtraTagFocused(false);
                                        addExtraTagRef.current.blur();
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            addExtraTagRef.current.blur();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <input
                type="file"
                accept="image/png, image/jpeg"
                ref={hiddenFileInput}
                onChange={(e) => handleInputPhoto(e)}
                style={{ display: 'none' }}
            />
            <div className="row-view justify-end mt20">
                <button
                    className="secondary-button mr20"
                    type='button'
                    onClick={() => navigate(-1)}
                >
                    Cancel
                </button>
                <button className="primary-button" type="submit">
                    Update
                </button>
            </div>
        </form >
    );
};