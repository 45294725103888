import React from 'react';
import { Link } from 'react-router-dom';

import dashboardIcon from "../../assets/icons/Dashboard.svg";
import calendarIcon from "../../assets/icons/Calendar.svg";
import reservationsIcon from "../../assets/icons/Reservations.svg";
import hotelsIcon from "../../assets/icons/Hotels.svg";
import staffIcon from "../../assets/icons/Contacts.svg";
import travelersIcon from "../../assets/icons/Users.svg";
import reviewsIcon from "../../assets/icons/Edit.svg";
import discussionsIcon from "../../assets/icons/Discussion.svg";
import ticketsIcon from "../../assets/icons/Tickets.svg";
import offersIcon from "../../assets/icons/offers.svg";

export const Drawer = ({ pathname }) => {
    return (
        <div className='drawer-container'>
            <Link
                to={{ pathname: '/dashboard' }}
                className={`row-view items-centers p20 drawer-link ${pathname.startsWith('/dashboard') && 'black-text bgDADADA rr100'}`}
            >
                <img
                    src={dashboardIcon}
                    alt='dashboard'
                    className={`icon mr20 ${!pathname.startsWith('/dashboard') && 'opacity60'}`}
                />
                <div>
                    Dashboard
                </div>
            </Link>
            <Link
                to={{ pathname: '/calendar' }}
                className={`row-view items-centers p20 drawer-link ${pathname.startsWith('/calendar') && 'black-text bgDADADA p20 rr100'}`}
            >
                <img
                    src={calendarIcon}
                    alt='calendar'
                    className={`icon mr20 ${!pathname.startsWith('/calendar') && 'opacity60'}`}
                />
                <div>
                    Calendar
                </div>
            </Link>
            <Link
                to={{ pathname: '/reservations', search: '?page=1&limit=10' }}
                className={`row-view items-centers p20 drawer-link ${pathname.startsWith('/reservations') && 'black-text bgDADADA p20 rr100'}`}
            >
                <img
                    src={reservationsIcon}
                    alt='reservations'
                    className={`icon mr20 ${!pathname.startsWith('/reservations') && 'opacity60'}`}
                />
                <div>
                    Reservations
                </div>
            </Link>
            <Link
                to={{ pathname: '/hotels', search: '?page=1&limit=10&search=' }}
                className={`row-view items-centers p20 drawer-link ${pathname.startsWith('/hotels') && 'black-text bgDADADA p20 rr100'}`}
            >
                <img
                    src={hotelsIcon}
                    alt='hotels'
                    className={`icon mr20 ${!pathname.startsWith('/hotels') && 'opacity60'}`}
                />
                <div>
                    Hotels
                </div>
            </Link>
            {/* <Link
                to={{ pathname: '/profiles' }}
                className={`row-view items-centers p20 drawer-link ${pathname.startsWith('/profiles') && 'black-text bgDADADA p20 rr100'}`}
            >
                <img
                    src={moderationIcon}
                    alt='profiles'
                    className={`icon mr20 ${!pathname.startsWith('/profiles') && 'opacity60'}`}
                />
                <div>
                    Profiles
                </div>
            </Link> */}
            <Link
                to={{ pathname: '/offers' }}
                className={`row-view items-centers p20 drawer-link ${pathname.startsWith('/offers') && 'black-text bgDADADA p20 rr100'}`}
            >
                <img
                    src={offersIcon}
                    alt='offers'
                    className={`icon mr20 ${!pathname.startsWith('/offers') && 'opacity60'}`}
                />
                <div>
                    Offers
                </div>
            </Link>
            <Link
                to={{ pathname: '/staff', search: '?page=1&limit=10&search=' }}
                className={`row-view items-centers p20 drawer-link ${pathname.startsWith('/staff') && 'black-text bgDADADA p20 rr100'}`}
            >
                <img
                    src={staffIcon}
                    alt='staff'
                    className={`icon mr20 ${!pathname.startsWith('/staff') && 'opacity60'}`}
                />
                <div>
                    Staff
                </div>
            </Link>
            <Link
                to={{ pathname: '/travelers' }}
                className={`row-view items-centers p20 drawer-link ${pathname.startsWith('/travelers') && 'black-text bgDADADA p20 rr100'}`}
            >
                <img
                    src={travelersIcon}
                    alt='travelers'
                    className={`icon mr20 ${!pathname.startsWith('/travelers') && 'opacity60'}`}
                />
                <div>
                    Travelers
                </div>
            </Link>
            <Link
                to={{ pathname: '/reviews' }}
                className={`row-view items-centers p20 drawer-link ${pathname.startsWith('/reviews') && 'black-text bgDADADA p20 rr100'}`}
            >
                <img
                    src={reviewsIcon}
                    alt='reviews'
                    className={`icon mr20 ${!pathname.startsWith('/reviews') && 'opacity60'}`}
                />
                <div>
                    Reviews
                </div>
            </Link>
            <Link
                to={{ pathname: '/discussions' }}
                className={`row-view items-centers p20 drawer-link ${pathname.startsWith('/discussions') && 'black-text bgDADADA p20 rr100'}`}
            >
                <img
                    src={discussionsIcon}
                    alt='discussions'
                    className={`icon mr20 ${!pathname.startsWith('/discussions') && 'opacity60'}`}
                />
                <div>
                    Discussions
                </div>
            </Link>
            <Link
                to={{ pathname: '/tickets' }}
                className={`row-view items-centers p20 drawer-link ${pathname.startsWith('/tickets') && 'black-text bgDADADA p20 rr100'}`}
            >
                <img
                    src={ticketsIcon}
                    alt='tickets'
                    className={`icon mr20 ${!pathname.startsWith('/tickets') && 'opacity60'}`}
                />
                <div>
                    Tickets
                </div>
            </Link>
            {/* <Link
                to={{ pathname: '/advantages' }}
                className={`row-view items-centers p20 drawer-link ${pathname.startsWith('/advantages') && 'black-text bgDADADA p20 rr100'}`}
            >
                <img
                    src={ticketsIcon}
                    alt='advantages'
                    className={`icon mr20 ${!pathname.startsWith('/advantages') && 'opacity60'}`}
                />
                <div>
                    Advantages
                </div>
            </Link> */}
            {/* <Link
                to={{ pathname: '/services' }}
                className={`row-view items-centers p20 drawer-link ${pathname.startsWith('/services') && 'black-text bgDADADA p20 rr100'}`}
            >
                <img
                    src={ticketsIcon}
                    alt='services'
                    className={`icon mr20 ${!pathname.startsWith('/services') && 'opacity60'}`}
                />
                <div>
                    Services
                </div>
            </Link> */}
        </div>
    )
}