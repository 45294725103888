import React, { useEffect, useRef, useState } from 'react'

import logoTextblack from '../../assets/logos/logo-text-black.svg'
import { Link } from 'react-router-dom'
import './../../iltm2023.css'
import { BiSolidChevronDown } from 'react-icons/bi'
import { MdClose } from 'react-icons/md';
import step2 from '../../assets/iltm2023/Step2.svg'
import { hotel_profile_tags } from '../../utils/mock/HotelData';
import { iltmUpdatekUser } from '../../api/iltm'

export const SetProfileTag = ({ setActiveSubTab, userContent, setUserContent }) => {
    const [user, setUser] = useState({});
    const [languageOpen, setLanguageOpen] = useState(false)

    const [addAppreciationTag, setAddAppreciationTag] = useState('');
    const [addAppreciationTagFocused, setAddAppreciationTagFocused] = useState(false);
    const addAppreciationTagRef = useRef(null);

    const [addMusicStyleTag, setAddMusicStyleTag] = useState('');
    const [addMusicStyleTagFocused, setAddMusicStyleTagFocused] = useState(false);
    const addMusicStyleTagRef = useRef(null);

    const [addFoodTypeTag, setAddFoodTypeTag] = useState('');
    const [addFoodTypeTagFocused, setAddFoodTypeTagFocused] = useState(false);
    const addFoodTypeTagRef = useRef(null);

    const [addFoodRestrictionTag, setAddFoodRestrictionTag] = useState('');
    const [addFoodRestrictionTagFocused, setAddFoodRestrictionTagFocused] = useState(false);
    const addFoodRestrictionTagRef = useRef(null);

    const [addAllergiesTag, setAddAllergiesTag] = useState('');
    const [addAllergiesTagFocused, setAddAllergiesTagFocused] = useState(false);
    const addAllergiesTagRef = useRef(null);

    useEffect(() => {
        setUser(userContent)
    }, [])

    const handleTag = (key, value) => {
        if (user[key] === undefined) {
            setUser({ ...user, [key]: [value] });
            return
        }
        if (user[key].includes(value)) {
            setUser({ ...user, [key]: user[key].filter(el => el !== value) });
        } else {
            setUser({ ...user, [key]: [...user[key], value] });
        }
    }

    const handleSelect = (key, value) => {
        if (user[key]) {
            if (user[key].includes(value)) {
                setUser({ ...user, [key]: user[key].filter(el => el !== value) })
            } else {
                setUser({ ...user, [key]: [...user[key], value] });
            }
        } else {
            setUser({ ...user, [key]: [value] })
        }
    }

    const handleRemoveSelect = (key, value) => {
        if (user[key].includes(value)) {
            setUser({ ...user, [key]: user[key].filter(el => el !== value) })
        }
    }

    const handleSubmit = () => {
        iltmUpdatekUser(user, user?.sub, response => {
            if (response?.status === 'success') {
                setUserContent(response?.content)
                setActiveSubTab(2)
            } else {
                alert('Error')
            }
        })
    }

    return (
        <div className='column-view justify-between' style={{ padding: '2vw', height: '90vh' }}>
            <div className='flex02'>
                <div className="column-view items-center flex03 mt20">
                    <img
                        src={logoTextblack}
                        alt="logoTextWhite"
                        style={{ width: '14rem' }}
                    />
                </div>
                <div className="column-view items-center justify-center">
                    <div className='mt20'>
                        <img src={step2} alt='step2' />
                    </div>
                    <div className="bold mt20">
                        Hey {user?.firstname}, profile yourself
                    </div>
                    <div className="mt10">Verify your informations</div>
                </div>
            </div>
            <div className='flex1 column-view'>
                <div className="border-container r8 mt20">
                    <div className="row-view items-center justify-center p20 bb wrap">
                        <div className="section-title flex04">I'm appreciated for</div>
                        <div className="row-view items-center wrap flex1">
                            {hotel_profile_tags.profiling_user.preferences.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`row-view tag mr20 mt10 mb10 ${user?.tagPreferences && user?.tagPreferences?.includes(item) &&
                                            'selected'
                                            }`}
                                        onClick={() =>
                                            handleTag('tagPreferences', item)
                                        }
                                    >
                                        <div>{item.icon} {item.label}</div>
                                    </div>
                                )
                            })}
                            {user?.tagPreferences?.filter(el => !hotel_profile_tags.profiling_user.preferences.includes(el))?.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`row-view tag mr20 mt10 mb10 ${user?.tagPreferences && user?.tagPreferences?.includes(item) &&
                                            'selected'
                                            }`}
                                        onClick={() =>
                                            handleTag('tagPreferences', item)
                                        }
                                    >
                                        <div>{item.icon} {item.label}</div>
                                    </div>
                                )
                            })}
                            <input
                                type='text'
                                ref={addAppreciationTagRef}
                                placeholder={!addAppreciationTagFocused && '+Add tag'}
                                className={`borderless none-outline mr20 mt10 mb10 ${addAppreciationTagFocused && 'tag'}`}
                                size={addAppreciationTag.length || 4}
                                value={addAppreciationTag}
                                onChange={(e) => setAddAppreciationTag(e.target.value)}
                                onFocus={() => setAddAppreciationTagFocused(true)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        if (user?.tagPreferences?.includes(addAppreciationTag)) {
                                            setAddAppreciationTag('')
                                            setAddAppreciationTagFocused(false)
                                            addAppreciationTagRef.current.blur()
                                            return
                                        }
                                        let tags = user?.tagPreferences ?? []
                                        tags.push({ icon: '', label: addAppreciationTag })
                                        setUser({ ...user, tagPreferences: tags })
                                        setAddAppreciationTag('')
                                        setAddAppreciationTagFocused(false)
                                        addAppreciationTagRef.current.blur()
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row-view items-center p20 bb">
                        <div className="section-title flex04">Select language</div>
                        <div className='row-view flex1 items-center'>
                            <div className="relative w250">
                                <div
                                    className="border-container bgFAFAFA row-view items-center justify-between r8 pv12 ph20 cursor-pointer"
                                    onClick={() => setLanguageOpen(!languageOpen)}
                                >
                                    <div>Select language</div>
                                    <BiSolidChevronDown />
                                </div>
                                <div className={`select-menu ${languageOpen && 'open'}`}>
                                    {hotel_profile_tags.profiling_user.favorite_food_type?.length !== 0 ? (
                                        <>
                                            {hotel_profile_tags.profiling_user.favorite_food_type.map((language, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="select-item"
                                                        onClick={() => {
                                                            handleSelect(
                                                                'tagLanguages',
                                                                language
                                                            )
                                                            setLanguageOpen(false)
                                                        }}
                                                    >
                                                        <span className="section-title">
                                                            {language.icon} {language.label}
                                                        </span>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            <div className="select-item">
                                                No languages found
                                            </div>
                                        </>
                                    )}
                                </div>
                                {user?.tagLanguages?.length !== 0 && user?.tagLanguages?.map((l, i) => {
                                    return (
                                        <div key={i} className='border-container r8 row-view items-center justify-between ph20 pv12 mt10'>
                                            <div>{l.icon} {l.label}</div>
                                            <MdClose onClick={() => handleRemoveSelect('tagLanguages', l)} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row-view items-center justify-center p20 bb wrap">
                        <div className="section-title flex04">My music style</div>
                        <div className="row-view items-center wrap flex1">
                            {hotel_profile_tags.profiling_user.music_brand_identity.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`row-view tag mr20 mt10 mb10 ${user?.tagMusicStyle && user?.tagMusicStyle?.includes(item) &&
                                            'selected'
                                            }`}
                                        onClick={() =>
                                            handleTag('tagMusicStyle', item)
                                        }
                                    >
                                        <div>{item.icon} {item.label}</div>
                                    </div>
                                )
                            })}
                            {user?.tagMusicStyle?.filter(el => !hotel_profile_tags.profiling_user.music_brand_identity.includes(el))?.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`row-view tag mr20 mt10 mb10 ${user?.tagMusicStyle && user?.tagMusicStyle?.includes(item) &&
                                            'selected'
                                            }`}
                                        onClick={() =>
                                            handleTag('tagMusicStyle', item)
                                        }
                                    >
                                        <div>{item.icon} {item.label}</div>
                                    </div>
                                )
                            })}
                            <input
                                type='text'
                                ref={addMusicStyleTagRef}
                                placeholder={!addMusicStyleTagFocused && '+Add tag'}
                                className={`borderless none-outline mr20 mt10 mb10 ${addMusicStyleTagFocused && 'tag'}`}
                                size={addMusicStyleTag.length || 4}
                                value={addMusicStyleTag}
                                onChange={(e) => setAddMusicStyleTag(e.target.value)}
                                onFocus={() => setAddMusicStyleTagFocused(true)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        if (user?.tagMusicStyle?.includes(addMusicStyleTag)) {
                                            setAddMusicStyleTag('')
                                            setAddMusicStyleTagFocused(false)
                                            addMusicStyleTagRef.current.blur()
                                            return
                                        }
                                        let tags = user?.tagMusicStyle ?? []
                                        tags.push({ icon: '', label: addMusicStyleTag })
                                        setUser({ ...user, tagMusicStyle: tags })
                                        setAddMusicStyleTag('')
                                        setAddMusicStyleTagFocused(false)
                                        addMusicStyleTagRef.current.blur()
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row-view items-center justify-center p20 bb wrap">
                        <div className="section-title flex04">Favorite food type</div>
                        <div className="row-view items-center wrap flex1">
                            {hotel_profile_tags.profiling_user.favorite_food_type.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`row-view tag mr20 mt10 mb10 ${user?.tagFoodType && user?.tagFoodType?.includes(item) &&
                                            'selected'
                                            }`}
                                        onClick={() =>
                                            handleTag('tagFoodType', item)
                                        }
                                    >
                                        <div>{item.icon} {item.label}</div>
                                    </div>
                                )
                            })}
                            {user?.tagFoodType?.filter(el => !hotel_profile_tags.profiling_user.favorite_food_type.includes(el))?.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`row-view tag mr20 mt10 mb10 ${user?.tagFoodType && user?.tagFoodType?.includes(item) &&
                                            'selected'
                                            }`}
                                        onClick={() =>
                                            handleTag('tagFoodType', item)
                                        }
                                    >
                                        <div>{item.icon} {item.label}</div>
                                    </div>
                                )
                            })}
                            <input
                                type='text'
                                ref={addFoodTypeTagRef}
                                placeholder={!addFoodRestrictionTagFocused && '+Add tag'}
                                className={`borderless none-outline mr20 mt10 mb10 ${addFoodRestrictionTagFocused && 'tag'}`}
                                size={addFoodTypeTag.length || 4}
                                value={addFoodTypeTag}
                                onChange={(e) => setAddFoodTypeTag(e.target.value)}
                                onFocus={() => setAddFoodTypeTagFocused(true)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        if (user?.tagFoodType?.includes(addFoodTypeTag)) {
                                            setAddFoodTypeTag('')
                                            setAddFoodTypeTagFocused(false)
                                            addFoodTypeTagRef.current.blur()
                                            return
                                        }
                                        let tags = user?.tagFoodType ?? []
                                        tags.push({ icon: '', label: addFoodTypeTag })
                                        setUser({ ...user, tagFoodType: tags })
                                        setAddFoodTypeTag('')
                                        setAddFoodTypeTagFocused(false)
                                        addFoodTypeTagRef.current.blur()
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row-view items-center justify-center p20 bb wrap">
                        <div className="section-title flex04">My food restrictions</div>
                        <div className="row-view items-center wrap flex1">
                            {hotel_profile_tags.profiling_user.food_restrictions.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`row-view tag mr20 mt10 mb10 ${user?.tagFoodRestrictions && user?.tagFoodRestrictions?.includes(item) &&
                                            'selected'
                                            }`}
                                        onClick={() =>
                                            handleTag('tagFoodRestrictions', item)
                                        }
                                    >
                                        <div>{item.icon} {item.label}</div>
                                    </div>
                                )
                            })}
                            {user?.tagFoodRestrictions?.filter(el => !hotel_profile_tags.profiling_user.food_restrictions.includes(el))?.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`row-view tag mr20 mt10 mb10 ${user?.tagFoodRestrictions && user?.tagFoodRestrictions?.includes(item) &&
                                            'selected'
                                            }`}
                                        onClick={() =>
                                            handleTag('tagFoodRestrictions', item)
                                        }
                                    >
                                        <div>{item.icon} {item.label}</div>
                                    </div>
                                )
                            })}
                            <input
                                type='text'
                                ref={addFoodRestrictionTagRef}
                                placeholder={!addFoodRestrictionTagFocused && '+Add tag'}
                                className={`borderless none-outline mr20 mt10 mb10 ${addFoodRestrictionTagFocused && 'tag'}`}
                                size={addFoodRestrictionTag.length || 4}
                                value={addFoodRestrictionTag}
                                onChange={(e) => setAddFoodRestrictionTag(e.target.value)}
                                onFocus={() => setAddFoodRestrictionTagFocused(true)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        if (user?.tagFoodRestrictions?.includes(addFoodRestrictionTag)) {
                                            setAddFoodRestrictionTag('')
                                            setAddFoodRestrictionTagFocused(false)
                                            addFoodRestrictionTagRef.current.blur()
                                            return
                                        }
                                        let tags = user?.tagFoodRestrictions ?? []
                                        tags.push({ icon: '', label: addFoodRestrictionTag })
                                        setUser({ ...user, tagFoodRestrictions: tags })
                                        setAddFoodRestrictionTag('')
                                        setAddFoodRestrictionTagFocused(false)
                                        addFoodRestrictionTagRef.current.blur()
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row-view items-center justify-center p20 bb wrap">
                        <div className="section-title flex04">My allergies</div>
                        <div className="row-view items-center wrap flex1">
                            {hotel_profile_tags.profiling_user.allergies.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`row-view tag mr20 mt10 mb10 ${user?.tagAllergies && user?.tagAllergies?.includes(item) &&
                                            'selected'
                                            }`}
                                        onClick={() =>
                                            handleTag('tagAllergies', item)
                                        }
                                    >
                                        <div>{item.icon} {item.label}</div>
                                    </div>
                                )
                            })}
                            {user?.tagAllergies?.filter(el => !hotel_profile_tags.profiling_user.allergies.includes(el))?.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`row-view tag mr20 mt10 mb10 ${user?.tagAllergies && user?.tagAllergies?.includes(item) &&
                                            'selected'
                                            }`}
                                        onClick={() =>
                                            handleTag('tagAllergies', item)
                                        }
                                    >
                                        <div>{item.icon} {item.label}</div>
                                    </div>
                                )
                            })}
                            <input
                                type='text'
                                ref={addAllergiesTagRef}
                                placeholder={!addAllergiesTagFocused && '+Add tag'}
                                className={`borderless none-outline mr20 mt10 mb10 ${addAllergiesTagFocused && 'tag'}`}
                                size={addAllergiesTag.length || 4}
                                value={addAllergiesTag}
                                onChange={(e) => setAddAllergiesTag(e.target.value)}
                                onFocus={() => setAddAllergiesTagFocused(true)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        if (user?.tagAllergies?.includes(addAllergiesTag)) {
                                            setAddAllergiesTag('')
                                            setAddAllergiesTagFocused(false)
                                            addAllergiesTagRef.current.blur()
                                            return
                                        }
                                        let tags = user?.tagAllergies ?? []
                                        tags.push({ icon: '', label: addAllergiesTag })
                                        setUser({ ...user, tagAllergies: tags })
                                        setAddAllergiesTag('')
                                        setAddAllergiesTagFocused(false)
                                        addAllergiesTagRef.current.blur()
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row-view justify-center">
                <Link
                    className="primary-button none-decoration-link row-view items-center mt50 ph100 mb50"
                    style={{ borderRadius: '25px' }}
                    onClick={() => handleSubmit()}
                >
                    <div cla>Continue</div>
                </Link>
            </div>
        </div>
    )
}
