import React, { useEffect, useRef, useState } from 'react';

import addImg from '../../assets/icons/AddImgOrange.svg';

import { MdChevronLeft, MdClose, MdCheck } from 'react-icons/md';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { BiSolidChevronDown } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import useHotelApi from '../../api/hotel';
import colors from '../../colors/colors';
import { createUser, getUserListByRole } from '../../api/user';
import { hotel_profile_tags } from '../../utils/mock/HotelData';

export const AddStaff = ({ handleLogout }) => {
    const { getHotelListLazy } = useHotelApi();
    const navigate = useNavigate();
    const [infoModal, setInfoModal] = useState(false);
    const [hotelsOpen, setHotelsOpen] = useState(false);
    const hotelsRef = useRef(null);
    const [hotelsSearch, setHotelsSearch] = useState('');
    const [roles, setRoles] = useState([]);
    const [hotel, setHotel] = useState({});
    const [hotels, setHotels] = useState([]);
    const [managersOpen, setManagersOpen] = useState(false);
    const managersRef = useRef(null);
    const [managersSearch, setManagersSearch] = useState('');
    const [manager, setManager] = useState({});
    const [managers, setManagers] = useState([]);

    const [staff, setStaff] = useState({
        phone: [''],
        tagAppreciations: [],
        tagLanguages: [],
        tagMusicStyle: [],
        tagFoodType: [],
        tagFoodRestrictions: [],
        tagAllergies: []
    });

    const hiddenFileInput = React.useRef(null);

    const [photoInput, setPhotoInput] = useState(null);

    const handlePhoto = (key) => {
        hiddenFileInput.current.click();
        setPhotoInput(key);
        console.log(key);
    };

    const handleInputPhoto = async (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            console.log("here");

            reader.onloadend = async () => {
                const img = new Image();
                img.src = reader.result;

                img.onload = async () => {
                    const width = img.width;
                    const height = img.height;
                    const isPortrait = width < height;
                    const compressedImageDataURL = await compressImage(reader.result, 0.4);
                    let key = photoInput;
                    setStaff({
                        ...staff,
                        [key]: compressedImageDataURL,
                    });
                    setPhotoInput(null);
                };
            };

            reader.readAsDataURL(file);
        }
    };

    const compressImage = async (imageUrl, quality) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = img.width;
                canvas.height = img.height;

                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                canvas.toBlob((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(blob);
                }, 'image/jpeg', quality);
            };
        });
    };

    const handleTag = (key, value) => {
        if (staff[key] === undefined) {
            setStaff({ ...staff, [key]: [value] });
            return;
        }
        if (containsObject(value, staff[key])) {
            setStaff({ ...staff, [key]: staff[key].filter((el) => el?.icon !== value?.icon && el?.label !== value?.label) });
        } else {
            setStaff({ ...staff, [key]: [...staff[key], value] });
        }
    };

    const containsObject = (obj, list) => {
        return list.some(item => item.icon === obj.icon && item.label === obj.label);
    };

    const handleInputText = (e) => {
        setStaff({ ...staff, [e.target.name]: e.target.value });
    };

    const handleSubmit = () => {
        if (roles?.length === 0) {
            alert("Select a role!");
            return 0;
        }

        let user = {
            ...staff,
            roles: roles
        };

        if (!manager?.sub && roles?.includes('hotelStaff')) {
            alert("Select a manager !");
            return 0;
        }

        user["manager_sub"] = manager.sub;

        if (hotel?.sub) {
            user["hotel_sub"] = hotel.sub;
        }

        createUser(user, response => {
            if (response?.status === 'success') {
                navigate(-1);
            } else {
                alert(response?.message);
            }
        });
    };

    useEffect(() => {
        let token = (localStorage.getItem('auth'));
        if (token) {
            const expiration = JSON.parse(localStorage.getItem('expiration'));
            if (expiration < Date.now() / 1000) {
                handleLogout();
            }
        }
    }, [handleLogout]);

    const retrieveHotels = (search) => {
        getHotelListLazy(0, 10, search, undefined, response => {
            if (response?.status === 'success') {
                setHotels(response?.content?.hotels);
            }
        });
    };

    useEffect(() => {
        retrieveHotels(hotelsSearch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retrieveManagers = (search) => {
        getUserListByRole(0, 10, search, 'hotelManager', response => {
            if (response?.status === 'success') {
                setManagers(response?.content?.users);
            }
        });
    };

    useEffect(() => {
        retrieveManagers(hotelsSearch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (managersOpen && managersRef.current && !managersRef.current.contains(event.target)) {
                setManagersOpen(false);
            }

            if (hotelsOpen && hotelsRef.current && !hotelsRef.current.contains(event.target)) {
                setHotelsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [
        managersOpen,
        managersRef,
        hotelsOpen,
        hotelsRef
    ]);

    return (
        <form
            className={`page-container`}
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
        >
            {infoModal &&
                <div
                    className='filter-darker'
                    onClick={() => {
                        if (infoModal) {
                            setInfoModal(false);
                        }
                    }}
                />
            }
            <div className='row-view items-center'>
                <MdChevronLeft
                    size={50}
                    onClick={() => navigate(-1)}
                />
                <div className='title-secondary'>Add Staff</div>
            </div>

            <div className='subtitle mt50 ml50'>General information</div>
            <div className='border-container r8 mt20'>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>First name<span className='primary-text-color'>*</span></div>
                    <div className='row-view flex1 items-center'>
                        <input className='input-text w300 ph20' required name='firstname' placeholder='First name...' value={staff?.firstname} onChange={(e) => handleInputText(e)} />
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Last name<span className='primary-text-color'>*</span></div>
                    <div className='row-view flex1 items-center'>
                        <input className='input-text w300 ph20' required name='lastname' placeholder='Last name...' value={staff?.lastname} onChange={(e) => handleInputText(e)} />
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Photo</div>
                    <div className='row-view flex1 items-center'>
                        {!staff?.photo ? (
                            <div className='row-view items-center gap20'>
                                <div
                                    className="row-view border-container b-dashed b2 r8 p20 cursor-pointer items-center justify-center"
                                    onClick={() => handlePhoto('photo')}
                                >
                                    <img src={addImg} alt="addImg" />
                                </div>
                                <div className="section-subtitle">
                                    (JPGs or PNGs of at least 150x150px)
                                </div>
                            </div>
                        ) : (
                            <div className="p10 relative">
                                <img
                                    className="r8 b"
                                    src={staff?.photo}
                                    alt="staffPhoto"
                                    style={{
                                        width: '80px',
                                        height: '80px',
                                        objectFit: 'contain',
                                    }}
                                />
                                <div
                                    className="close-icon"
                                    onClick={() =>
                                        setStaff({
                                            ...staff,
                                            photo: null
                                        })
                                    }
                                >
                                    <MdClose size={20} color="white" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Business card</div>
                    <div className='row-view flex1 items-center'>
                        {!staff?.businessCard ? (
                            <div className='row-view items-center gap20'>
                                <div
                                    className="row-view border-container b-dashed b2 r8 p20 cursor-pointer items-center justify-center"
                                    onClick={() => handlePhoto('businessCard')}
                                >
                                    <img src={addImg} alt="addImg" />
                                </div>
                                <div className="section-subtitle">
                                    (JPGs or PNGs of at least 150x150px)
                                </div>
                            </div>
                        ) : (
                            <div className="p10 relative">
                                <img
                                    className="r8 b"
                                    src={staff?.businessCard}
                                    alt="businessCard"
                                    style={{
                                        width: '80px',
                                        height: '80px',
                                        objectFit: 'contain',
                                    }}
                                />
                                <div
                                    className="close-icon"
                                    onClick={() =>
                                        setStaff({
                                            ...staff,
                                            businessCard: null
                                        })
                                    }
                                >
                                    <MdClose size={20} color="white" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Roles<span className='primary-text-color'>*</span></div>
                    <div className='column-view flex1'>
                        <div className='bgFAFAFA row-view items-center w300 p20 b r8 cursor-pointer' onClick={() => setRoles(['chainManager'])}>
                            <div className={`checkbox r4 mr10 ${roles && roles?.includes('chainManager') && 'selected'}`}>
                                {roles?.includes('chainManager') && <MdCheck color='white' />}
                            </div>
                            <div>Chain Manager</div>
                        </div>
                        <div className='bgFAFAFA row-view items-center w300 p20 mt10 b r8 cursor-pointer' onClick={() => setRoles(['hotelOwner'])}>
                            <div className={`checkbox r4 mr10 ${roles && roles?.includes('hotelOwner') && 'selected'}`}>
                                {roles?.includes('hotelOwner') && <MdCheck color='white' />}
                            </div>
                            <div>Owner</div>
                        </div>
                        <div className='bgFAFAFA row-view items-center w300 p20 mt10 b r8 cursor-pointer' onClick={() => setRoles(['hotelManager'])}>
                            <div className={`checkbox r4 mr10 ${roles && roles?.includes('hotelManager') && 'selected'}`}>
                                {roles?.includes('hotelManager') && <MdCheck color='white' />}
                            </div>
                            <div>Manager</div>
                        </div>
                        <div className='bgFAFAFA row-view items-center w300 p20 mt10 b r8 cursor-pointer' onClick={() => setRoles(['hotelStaff'])}>
                            <div className={`checkbox r4 mr10 ${roles && roles?.includes('hotelStaff') && 'selected'}`}>
                                {roles?.includes('hotelStaff') && <MdCheck color='white' />}
                            </div>
                            <div>Staff</div>
                        </div>
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Availability</div>
                    <div className='row-view flex1 items-center'>
                        <div className='row-view items-center w300'>
                            <div className={`toggle mr10 ${staff?.available && 'checked'}`} onClick={() => setStaff({ ...staff, available: !staff?.available })}>
                                <div className={`toggle-span ${staff?.available && 'checked'}`} />
                            </div>
                            <div className='section-title mr10'>Available</div>
                            <div className='relative row-view items-center'>
                                <BsFillQuestionCircleFill color={colors.primary} size={20} onClick={() => setInfoModal(!infoModal)} />
                                <div className={`info-modal ${infoModal && 'active'}`}>
                                    Is this hotelier unavailable or on vacation? Update its status to <span className='primary-text-color bold'>"not available"</span> to notify every member. The hotelier will be <span className='primary-text-color bold'>hidden in the application</span> during this period.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Email address<span className='primary-text-color'>*</span></div>
                    <div className='row-view flex1 items-center'>
                        <input className='input-text w300 ph20' required type='email' name='email' placeholder='Email address...' value={staff?.email} onChange={(e) => handleInputText(e)} />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Phone number</div>
                    <div className='column-view flex1 justify-between'>
                        {staff?.phone?.map((item, index) => {
                            return (
                                <div key={index} className='row-view items-center gap20 wrap mb10'>
                                    <input
                                        className="input-text"
                                        name="phone"
                                        placeholder="Phone number..."
                                        value={item}
                                        onChange={(e) => {
                                            let phones = staff?.phone;
                                            phones[index] = e.target.value;
                                            setStaff({ ...staff, phone: phones });
                                        }}
                                    />
                                    {staff?.phone?.length > 1 && <MdClose className='cursor-pointer' color={colors.primary} size={25} onClick={() => setStaff({ ...staff, phone: staff.phone.splice(item, 1) })} />}
                                </div>
                            );
                        })}
                        <div className='row-view'>
                            <div className='secondary-button borderless p12 grey-text' onClick={() => setStaff({ ...staff, phone: [...staff.phone, ''] })}>+Add phone</div>
                        </div>
                    </div>
                </div>
                {['admin', 'hotelAdmin', 'hotelManager', 'hotelOwner'].every(i => !roles?.includes(i)) &&
                    <div className='row-view items-center p20 bb'>
                        <div className='section-title flex04'>Manager<span className='primary-text-color'>*</span></div>
                        <div className='row-view flex1 items-center'>
                            <div className='relative' style={{ paddingTop: "2%", paddingBottom: "2%", display: "inline-block" }}>
                                {managersOpen ?
                                    <input
                                        type='text'
                                        value={managersSearch}
                                        className='input-text w300'
                                        autoFocus
                                        onChange={(e) => {
                                            setManagersSearch(e.target.value);
                                            retrieveManagers(e.target.value);
                                        }}
                                    />
                                    :
                                    <div
                                        className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer'
                                        onClick={() => setManagersOpen(!managersOpen)}
                                    >
                                        <div>
                                            {manager?.firstname && manager?.lastname ? `${manager?.firstname} ${manager?.lastname}` : 'Select manager'}
                                        </div>
                                        <BiSolidChevronDown />
                                    </div>
                                }
                                <div className={`select-menu ${managersOpen && 'open'}`} style={{ maxHeight: 300, overflow: "scroll" }} ref={managersRef}>
                                    {managers?.length !== 0 ? (
                                        <>
                                            {managers?.map((m, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className='select-item'
                                                        onClick={() => {
                                                            setManager(m);
                                                            setManagersOpen(false);
                                                        }}
                                                    >
                                                        <span className='section-title'>
                                                            {m?.firstname} {m?.lastname}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            <div className='select-item'>
                                                No managers found
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Position</div>
                    <div className='row-view flex1 items-center'>
                        <input className='input-text w300 ph20' name='position' placeholder='Position...' value={staff?.position} onChange={(e) => handleInputText(e)} />
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Linkedin</div>
                    <div className='row-view flex1 items-center'>
                        <input className='input-text w300 ph20' name='linkedin' placeholder='Linkedin link...' value={staff?.linkedin} onChange={(e) => handleInputText(e)} />
                    </div>
                </div>
                <div className='row-view items-center p20'>
                    <div className='section-title flex04'>Hotel linked</div>
                    <div className='row-view flex1 items-center'>
                        <div className='relative' style={{ paddingTop: "2%", paddingBottom: "2%", display: "inline-block" }}>
                            {hotelsOpen ?
                                <input
                                    type='text'
                                    value={hotelsSearch}
                                    className='input-text w300'
                                    autoFocus
                                    onChange={(e) => {
                                        setHotelsSearch(e.target.value);
                                        retrieveHotels(e.target.value);
                                    }}
                                />
                                :
                                <div
                                    className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer'
                                    onClick={() => setHotelsOpen(!hotelsOpen)}
                                >
                                    <div>
                                        {hotel?.name
                                            ? hotel?.name
                                            : 'Select hotel'}
                                    </div>
                                    <BiSolidChevronDown />
                                </div>
                            }
                            <div className={`select-menu ${hotelsOpen && 'open'}`} style={{ maxHeight: 300, overflow: "scroll" }} ref={hotelsRef}>
                                {hotels?.length !== 0 ? (
                                    <>
                                        {hotels?.map((h, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className='select-item'
                                                    onClick={() => {
                                                        setHotel(h);
                                                        setHotelsOpen(false);
                                                    }}
                                                >
                                                    <span className='section-title'>
                                                        {h?.name}<span className='grey-text'> - {h?.address?.city}</span>
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <div className='select-item'>
                                            No hotels found
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='subtitle mt50 mlq0'>Specifications</div>
            <div className='border-container r8 mt20'>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Appreciated for</div>
                    <div className='row-view wrap flex1'>
                        {hotel_profile_tags.profiling_user.preferences.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view items-center tag mr20 mt10 mb10 ${staff?.tagAppreciations && containsObject(item, staff?.tagAppreciations) && 'selected'}`}
                                    onClick={() => handleTag('tagAppreciations', item)}
                                >
                                    <div className='mr10'>{item.icon}</div>
                                    <div>{item.label}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Spoken languages</div>
                    <div className='row-view wrap flex1'>
                        {hotel_profile_tags.profiling_user.spoken_languages.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view items-center tag mr20 mt10 mb10 ${staff?.tagLanguages && containsObject(item, staff?.tagLanguages) && 'selected'}`}
                                    onClick={() => handleTag('tagLanguages', item)}
                                >
                                    <div className='mr10'>{item.icon}</div>
                                    <div>{item.label}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Music style</div>
                    <div className='row-view wrap flex1'>
                        {hotel_profile_tags.profiling_user.music_brand_identity.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view items-center tag mr20 mt10 mb10 ${staff?.tagMusicStyle && containsObject(item, staff?.tagMusicStyle) && 'selected'}`}
                                    onClick={() => handleTag('tagMusicStyle', item)}
                                >
                                    <div className='mr10'>{item.icon}</div>
                                    <div>{item.label}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Favorite food type</div>
                    <div className='row-view wrap flex1'>
                        {hotel_profile_tags.profiling_user.favorite_food_type.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view items-center tag mr20 mt10 mb10 ${staff?.tagFoodType && containsObject(item, staff?.tagFoodType) && 'selected'}`}
                                    onClick={() => handleTag('tagFoodType', item)}
                                >
                                    <div className='mr10'>{item.icon}</div>
                                    <div>{item.label}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Food restrictions</div>
                    <div className='row-view wrap flex1'>
                        {hotel_profile_tags.profiling_user.food_restrictions.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view items-center tag mr20 mt10 mb10 ${staff?.tagFoodRestrictions && containsObject(item, staff?.tagFoodRestrictions) && 'selected'}`}
                                    onClick={() => handleTag('tagFoodRestrictions', item)}
                                >
                                    <div className='mr10'>{item.icon}</div>
                                    <div>{item.label}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='row-view items-center p20'>
                    <div className='section-title flex04'>Allergies</div>
                    <div className='row-view wrap flex1'>
                        {hotel_profile_tags.profiling_user.allergies.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view items-center tag mr20 mt10 mb10 ${staff?.tagAllergies && containsObject(item, staff?.tagAllergies) && 'selected'}`}
                                    onClick={() => handleTag('tagAllergies', item)}
                                >
                                    <div className='mr10'>{item.icon}</div>
                                    <div>{item.label}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <input
                type="file"
                accept="image/png, image/jpeg"
                ref={hiddenFileInput}
                onChange={(e) => handleInputPhoto(e)}
                style={{ display: 'none' }}
            />
            <div className='row-view justify-end mt20'>
                <button className='secondary-button mr20' type='reset' onClick={() => navigate(-1)}>Cancel</button>
                <button className='primary-button' type='submit'>Create Staff</button>
            </div>
        </form>
    );
};