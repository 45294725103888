const getToken = async () => {
    return JSON.parse(localStorage.getItem('auth'));
}

export const getConversationList = async (callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/conversations`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const sendMessage = async (conversation_sub, message, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/conversation/${conversation_sub}/message`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "PUT",
        body: JSON.stringify(message)
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getConversation = async (conversation_sub, offset, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/conversations/${conversation_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};