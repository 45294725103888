import React, { useEffect, useRef, useState } from 'react';
import logoTextWhite from '../../assets/logos/logo-text-white.svg'
import { MdPerson } from 'react-icons/md';
import { AiFillBell } from 'react-icons/ai';
import { BiSolidChevronDown } from 'react-icons/bi'
import { Link, useLocation } from 'react-router-dom';
import useHotelApi from '../../api/hotel';

export const AppBar = ({ handleLogout, user }) => {
    const { getHotelListLazy } = useHotelApi();
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef();
    const location = useLocation();
    const [hotelsOpen, setHotelsOpen] = useState(false);
    const hotelsRef = useRef(null);
    const [hotelsSearch, setHotelsSearch] = useState('');
    const [hotels, setHotels] = useState([]);
    const [hotel, setHotel] = useState({});


    useEffect(() => {
        function handleClickOutside(event) {
            if (menuOpen && menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [
        menuOpen,
        menuRef
    ]);


    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize)



        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    const retrieveHotels = (search) => {
        getHotelListLazy(0, 10, search, undefined, response => {
            if (response?.status === 'success') {
                setHotels(response?.content?.hotels)
            }
        })
    }

    return (
        <div className='app-bar items-center'>
            <div className='flex1'>
                <img
                    src={logoTextWhite}
                    alt='logoTextWhite'
                    className='logo-small ml50'
                />            </div>


            {location.pathname.includes('/offers') && <div>
                <div className='relative' style={{ paddingTop: "2%", paddingBottom: "2%", display: "inline-block" }}>
                    {hotelsOpen ?
                        <input
                            type='text'
                            value={hotelsSearch}
                            className='input-text w300'
                            autoFocus
                            onChange={(e) => {
                                setHotelsSearch(e.target.value)
                                retrieveHotels(e.target.value)
                            }}
                        />
                        :
                        <div
                            className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer'
                            onClick={() => setHotelsOpen(!hotelsOpen)}
                        >
                            <div>
                                {hotel?.name
                                    ? hotel?.name
                                    : 'Select hotel'}
                            </div>
                            <BiSolidChevronDown />
                        </div>
                    }
                    <div className={`select-menu ${hotelsOpen && 'open'}`} style={{ maxHeight: 300, overflow: "scroll" }} ref={hotelsRef}>
                        {hotels?.length !== 0 ? (
                            <>
                                {hotels?.map((h, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='select-item'
                                            onClick={() => {
                                                setHotel(h)
                                                setHotelsOpen(false)
                                                localStorage.setItem('subOffer', JSON.stringify(h?.sub))
                                            }}
                                        >
                                            <span className='section-title'>
                                                {h?.name}<span className='grey-text'> - {h?.address?.city}</span>
                                            </span>
                                        </div>
                                    )
                                })}
                            </>
                        ) : (
                            <>
                                <div className='select-item'>
                                    No hotels found
                                </div>
                            </>
                        )}
                    </div>
                </div>

            </div>
            }
            {/* <div className='row-view items-center search-bar-container flex1'>
                <MdSearch color='white' size={30} />
                <input type='text' className='search-bar' placeholder='Search' />
            </div> */}
            <div className='row-view flex1 justify-end items-center mr50'>
                <AiFillBell color='white' size={30} className='mr10' />

                <div className='relative row-view justify-between items-center' >
                    {user?.photo ?
                        <img src={user?.photo} alt='userPhoto' className='mr10' style={{ width: 50, height: 50, objectFit: 'cover', borderRadius: 100 }} />
                        :
                        <MdPerson color='white' size={30} className='mr20' />
                    }
                    {windowSize > 965 &&
                        <div className='column-view mr20'>
                            <div className='app-username'>{user?.firstname} {user?.lastname}</div>
                            <div className='app-role'>
                                {user?.roles[0]?.replace('hotel', '')}
                            </div>
                        </div>
                    }
                    <BiSolidChevronDown
                        className='cursor-pointer'
                        color='white'
                        size={30}
                        onClick={() => setMenuOpen(!menuOpen)}
                    />
                    <div className={`select-menu top50 ${menuOpen && 'open'}`}>

                        <Link
                            style={{ fontWeight: 'normal' }}
                            className='none-decoration-link link'
                            to={{ pathname: '/settings' }}
                            onClick={() => {
                                setMenuOpen(false);
                            }}
                        >   <div className="select-item ">

                                Profile
                            </div>
                        </Link>

                        <div style={{ width: "100%", height: 2, backgroundColor: "#DADADA" }} />
                        <div
                            className='select-item'
                            onClick={() => {
                                setMenuOpen(false);
                                handleLogout();
                            }}
                        >
                            Logout
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}