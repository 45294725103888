import {
    BrowserRouter as Router,
    Route,
    Routes,
    useNavigate,
    useLocation,
} from 'react-router-dom';
import './App.css';
import './calendar.css';
import { AppBar } from './components/AppBar/AppBar';
import { Drawer } from './components/Drawer/Drawer';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Calendar } from './pages/Calendar/Calendar';
import { Reservations } from './pages/Reservations/Reservations';
import { Hotels } from './pages/Hotels/Hotels';
import { Staff } from './pages/Staff/Staff';
import { Travelers } from './pages/Travelers/Travelers';
import { Reviews } from './pages/Reviews/Reviews';
import { Discussions } from './pages/Discussions/Discussions';
import { Tickets } from './pages/Tickets/Tickets';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Login } from './pages/Login/Login';
import { login } from './api/auth';
import { getMyUser, getUser } from './api/user';
import { AddStaff } from './pages/Staff/AddStaff';
import { AddHotel } from './pages/Hotels/AddHotel';
import { TravelerProfile } from './pages/Travelers/TravelerProfile';
import { HotelProfile } from './pages/Hotels/Profiles/HotelProfile';
import { StaffProfile } from './pages/Staff/StaffProfile';
import { ViewReservation } from './pages/Reservations/ViewReservation';
import { AddTicket } from './pages/Tickets/AddTicket';
import { EditTicket } from './pages/Tickets/EditTicket';
import { Settings } from './pages/Settings/Settings';
import { Offers } from './pages/Offers/Offers';
import { AddOffer } from './pages/Offers/AddOffer';
import { EditOffer } from './pages/Offers/EditOffer';
import { Invite } from './pages/Invite/Invite';
import { AddRestaurants } from './pages/Hotels/Restaurants/AddRestaurants';
import { ILTM } from './pages/ILTM/ILTM';
import { SetPassword } from './pages/Login/SetPassword';
import Welcome from './pages/Welcome/Welcome';
import { NoAccess } from './pages/NoAccess/NoAccess';

const AppContentLoggedIn = forwardRef(({ handleLogout, user }, navigateRef) => {
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const navigate = useNavigate();
    const location = useLocation();
    const [pathname, setPathname] = useState(location.pathname);

    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/dashboard');
        } else if (location.pathname === '/itlm2023') {
            navigate('/iltm2023');
        }
        setPathname(location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const navigateTo = (path) => {
        navigate(path);
    };

    navigateRef.current = { navigateTo };

    if (location.pathname.includes('/welcome')) {
        return (
            <Welcome
                handleLogout={handleLogout}
            />
        );
    }
    else if (location.pathname.includes('/pending')) {
        return (
            <NoAccess
                handleLogout={handleLogout}
            />
        );
    } else {
        return (
            <>
                {location.pathname.includes('/invite') ||
                    location.pathname.includes('/iltm2023') ? (
                    <>
                        {location.pathname.includes('/invite') && <Invite />}
                        {location.pathname.includes('/iltm2023') && (
                            <>
                                <Routes>
                                    <Route path="/iltm2023" element={<ILTM />} />
                                </Routes>
                            </>
                        )}
                    </>
                ) : (
                    <div className="app-container">
                        <AppBar handleLogout={handleLogout} user={user} />
                        {windowSize > 965 ? (
                            <div className="page-content">
                                <Drawer pathname={pathname} />
                                <Routes>
                                    <Route
                                        path="/dashboard"
                                        element={
                                            <Dashboard
                                                handleLogout={handleLogout}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/calendar"
                                        element={
                                            <Calendar handleLogout={handleLogout} />
                                        }
                                    />
                                    <Route
                                        path="/reservations"
                                        element={
                                            <Reservations
                                                handleLogout={handleLogout}
                                            />
                                        }
                                    />

                                    <Route
                                        path="/hotels"
                                        element={
                                            <Hotels handleLogout={handleLogout} />
                                        }
                                    />
                                    <Route
                                        path="/hotels/add"
                                        element={
                                            <AddHotel handleLogout={handleLogout} />
                                        }
                                    />
                                    <Route
                                        path="/hotels/profile/:id"
                                        element={
                                            <HotelProfile
                                                handleLogout={handleLogout}
                                            />
                                        }
                                    />

                                    <Route
                                        path="/staff"
                                        element={
                                            <Staff handleLogout={handleLogout} />
                                        }
                                    />
                                    <Route
                                        path="/staff/add"
                                        element={
                                            <AddStaff handleLogout={handleLogout} />
                                        }
                                    />
                                    <Route
                                        path="/staff/profile/:id"
                                        element={
                                            <StaffProfile
                                                handleLogout={handleLogout}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/travelers"
                                        element={
                                            <Travelers
                                                handleLogout={handleLogout}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/travelers/profile/:id"
                                        element={
                                            <TravelerProfile
                                                handleLogout={handleLogout}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/reviews"
                                        element={
                                            <Reviews handleLogout={handleLogout} />
                                        }
                                    />
                                    <Route
                                        path="/discussions"
                                        element={
                                            <Discussions
                                                handleLogout={handleLogout}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/tickets"
                                        element={
                                            <Tickets handleLogout={handleLogout} />
                                        }
                                    />
                                    <Route
                                        path="/tickets/add"
                                        element={
                                            <AddTicket
                                                handleLogout={handleLogout}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/tickets/edit/:id"
                                        element={
                                            <EditTicket
                                                handleLogout={handleLogout}
                                            />
                                        }
                                    />

                                    <Route
                                        path="/reservations/:id"
                                        element={
                                            <ViewReservation
                                                handleLogout={handleLogout}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/settings"
                                        element={
                                            <Settings handleLogout={handleLogout} />
                                        }
                                    />
                                    <Route
                                        path="/offers"
                                        element={
                                            <Offers handleLogout={handleLogout} />
                                        }
                                    />
                                    <Route
                                        path="/offers/add"
                                        element={
                                            <AddOffer handleLogout={handleLogout} />
                                        }
                                    />
                                    <Route
                                        path="/offers/edit/:id"
                                        element={
                                            <EditOffer
                                                handleLogout={handleLogout}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/restaurants/add"
                                        element={
                                            <AddRestaurants
                                                handleLogout={handleLogout}
                                            />
                                        }
                                    />
                                </Routes>
                            </div>
                        ) : (
                            <div className="app-container">
                                <Routes>
                                    <Route
                                        path="/discussions"
                                        element={
                                            <Discussions
                                                handleLogout={handleLogout}
                                            />
                                        }
                                    />
                                </Routes>
                            </div>
                        )}
                    </div>
                )}
            </>
        );
    }
});

const AppContentLoggedOut = ({ setAuthToken, setUserSession }) => {
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const navigate = useNavigate();
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (location.pathname === '/itlm2023') {
            navigate('/iltm2023');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogin = (email, password) => {
        setErrorMessage('');
        login(email, password, (response) => {
            if (response?.status === 'success') {
                setAuthToken(response.auth);
                localStorage.setItem('auth', JSON.stringify(response.auth));
                localStorage.setItem('sub', JSON.stringify(response.sub));
                localStorage.setItem('expiration', response.expiration);
                setUserSession();
                if (windowSize < 965) {
                    navigate('/discussions');
                } else {
                    navigate('/dashboard');
                }
            } else {
                setErrorMessage(response.message);
            }
        });
    };

    if (
        location.pathname.includes('/invite') ||
        location.pathname.includes('/iltm2023')
    ) {
        if (location.pathname.includes('/invite')) {
            return <Invite />;
        } else if (location.pathname.includes('/iltm2023')) {
            return (
                <>
                    <Routes>
                        <Route path="/iltm2023" element={<ILTM />} />
                    </Routes>
                </>
            );
        }
    } else if (location.pathname.includes('/reset')) {
        return <SetPassword />;
    } else {
        return <Login handleLogin={handleLogin} errorMessage={errorMessage} />;
    }
};

const App = () => {
    const [user, setUser] = useState(null);
    const [authToken, setAuthToken] = useState(null);
    const [windowSize, setWindowSize] = useState(window.innerWidth);

    const navigateRef = useRef(null);
    const navigateTo = (path) => {
        if (navigateRef.current) {
            navigateRef.current.navigateTo(path);
        }
    };

    const setUserSession = () => {
        getMyUser((response) => {
            if (!('onboardingBO' in response?.content)) {
                navigateTo('/welcome');
            } else if (!response?.content?.roles?.includes('admin')) {
                if (!('hotel' in response?.content) || Object.keys(response?.content?.hotel).length === 0)
                    navigateTo('/pending');
            }
            setUser(response.content);
            localStorage.setItem(
                'my_roles',
                JSON.stringify(response.content.roles)
            );
        });
    };

    const handleLogout = () => {
        localStorage.clear();
        setAuthToken(null);
        navigateTo('/');
    };

    useEffect(() => {
        let token = localStorage.getItem('auth');
        if (token) {
            setAuthToken(token);

            setUserSession();

            const expiration = JSON.parse(localStorage.getItem('expiration'));
            if (expiration < Date.now() / 1000) {
                handleLogout();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Router>
            {authToken ? (
                <AppContentLoggedIn
                    handleLogout={handleLogout}
                    user={user}
                    ref={navigateRef}
                />
            ) : (
                <AppContentLoggedOut
                    setAuthToken={setAuthToken}
                    setUserSession={setUserSession}
                />
            )}
        </Router>
    );
};

export default App;
